import { useEffect, useCallback, useRef } from 'react';
import { useApiFavoritesListGetCollection } from '@ae/data-access';
import { useMe } from '@ae/shared';

type Props = {
  children: React.ReactElement | null;
  selectedRentalId: string;
};

export const RentalFavoritesNotifier = ({
  children,
  selectedRentalId,
}: Props) => {
  const rentalIdsQueue = useRef(new Set());
  const { authenticated } = useMe();
  const { data: favoritesList, isLoading: isFavoritesListLoading } =
    useApiFavoritesListGetCollection(
      {
        pagination: false,
      },
      {
        query: {
          onSuccess: () => {
            if (selectedRentalId.length) {
              rentalIdsQueue.current.add(selectedRentalId);
              processQuery();
            }
          },
          enabled: authenticated,
        },
      }
    );

  const processQuery = useCallback(() => {
    if (
      !isFavoritesListLoading &&
      favoritesList &&
      rentalIdsQueue.current.size > 0
    ) {
      const rentalId = rentalIdsQueue.current.values().next().value;

      const isRentalInFavoritesList = (rentalId: string) =>
        favoritesList?.['hydra:member']
          .flatMap((el) => el.favorites)
          .find((id) => rentalId === String(id)) !== undefined;

      document.dispatchEvent(
        new CustomEvent('rentalInFavoritesResponse', {
          detail: {
            rentalId: String(rentalId),
            favorite: isRentalInFavoritesList(rentalId),
          },
        })
      );
      rentalIdsQueue.current.delete(rentalId);
      processQuery();
    }
  }, [favoritesList, isFavoritesListLoading]);

  const onRegisterRentalInFavoritesListener = useCallback(
    (e: CustomEvent<{ rentalId: string }>) => {
      rentalIdsQueue.current.add(e.detail.rentalId);
      processQuery();
    },
    [processQuery]
  );

  useEffect(() => {
    document.addEventListener(
      'rentalInFavoritesQuery',
      onRegisterRentalInFavoritesListener as EventListener
    );

    document.dispatchEvent(
      new CustomEvent('rentalInFavoritesQueriesReady', {})
    );

    return () =>
      document.removeEventListener(
        'rentalInFavoritesQuery',
        onRegisterRentalInFavoritesListener as EventListener
      );
  }, [onRegisterRentalInFavoritesListener]);

  return children;
};
