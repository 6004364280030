import { defaultLocale, LanguageContext } from '@ae/i18n';
import { useCallback, useContext } from 'react';

import { useSearchUrlTranslation } from '../Mr';
import { useRouter } from '@ae/shared';

export const useChangeLocale = () => {
  const { getSearchUrl } = useSearchUrlTranslation();

  const { pathname, basePath } = useRouter();
  const { changeLocale } = useContext(LanguageContext);

  const defaultChangeLocale = useCallback(
    (newLocale: string) => {
      const host = window.location.host
        .replace('www.', '')
        .replace(/(fr|en|de|nl)\./, '')
        .replace(/\.(fr|en|de|nl)/, '.be');
      const hostPrefix = newLocale === defaultLocale ? 'www' : newLocale;
      const domain = `${window.location.protocol}//${hostPrefix}.${host}`;

      const { search } = new URL(window.location.href);

      window.location.href = `${domain}${
        pathname === '/' ? '' : pathname
      }${search}`;
    },
    [pathname, basePath, getSearchUrl]
  );

  return { changeLocale: changeLocale || defaultChangeLocale };
};
