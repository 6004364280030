import React, { HTMLInputTypeAttribute } from 'react';
import {
  Box,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Text,
} from '@chakra-ui/react';
import { UIFormLabel } from '@ae/shared-ui';

export type UIInputProps = ChakraInputProps & {
  dataTestId?: string;
  variant?: 'primary' | 'full';
  placeholder?: string;
  label?: string;
  type?: HTMLInputTypeAttribute;
  mb?: string;
  error?: string | undefined;
};

export const UIInput = React.forwardRef<HTMLInputElement, UIInputProps>(
  (
    {
      dataTestId,
      label,
      variant = 'primary',
      isRequired,
      placeholder,
      type,
      mb,
      error,
      ...others
    },
    ref
  ) => (
    <Box mb={mb ?? '15px'} w={others.w}>
      {label && <UIFormLabel label={label} isRequired={isRequired} />}
      <ChakraInput
        w="100%"
        ref={ref}
        data-testid={dataTestId}
        errorBorderColor="ae.red"
        placeholder={placeholder}
        type={type}
        fontSize="14px"
        isInvalid={!!error}
        p="1rem"
        h="40px"
        bgColor="white"
        borderRadius="5px"
        borderColor={error ? 'ae.red' : 'ae.grey_300'}
        focusBorderColor={error ? 'ae.red' : 'ae.grey_300'}
        color="ae.black"
        _placeholder={{ color: 'ae.grey_400' }}
        sx={{
          _hover: {
            borderColor: error ? 'ae.red' : 'ae.grey_300',
            focusBorderColor: error ? 'ae.red' : 'ae.grey_300',
          },
        }}
        {...others}
      />
      {error && (
        <Text color="ae.red" fontSize="14px">
          {error}
        </Text>
      )}
    </Box>
  )
);
