import {
  getApiFavoritesListGetCollectionQueryKey,
  useApiFavoritesListPost,
} from '@ae/data-access';
import { UIAlertMessage } from '@ae/shared-ui';
import { useTag } from '@ae/tagging';
import { useQueryClient } from 'react-query';
import { FormValues, RentalFavoriteListForm } from './RentalFavoriteListForm';
import { useTranslation } from '@ae/shared';

type Props = {
  onSuccess: () => void;
};

export const CreateRentalFavoriteListForm = ({ onSuccess }: Props) => {
  const trackEvent = useTag();
  const queryClient = useQueryClient();
  const { t } = useTranslation('favorites');
  const { mutate, isLoading, isError } = useApiFavoritesListPost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getApiFavoritesListGetCollectionQueryKey()
        );
        trackEvent({
          ga4: {
            event: 'client_account_cta',
            website_subsection: 'home_section',
            category: 'create_wishlist',
            action: 'main_cta',
          },
        });
        onSuccess();
      },
    },
  });

  const onSubmit = ({ listname }: FormValues) =>
    mutate({ data: { name: listname } });

  if (isError) {
    return (
      <UIAlertMessage
        description={t('favorites.list.error4')}
        status="error"
        displayIcon
      />
    );
  }
  return (
    <RentalFavoriteListForm onSubmit={onSubmit} isSubmitting={isLoading} />
  );
};
