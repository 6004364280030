import { axiosInstance } from '@ae/data-access';
import { getBaseUrl, setBaseUrl } from '@ae/i18n';
import { isAxiosError } from 'axios';
import { QueryClient } from 'react-query';

// NEVER DO THIS:
// const queryClient = new QueryClient()
//
// Creating the queryClient at the file root level makes the cache shared
// between all requests and means _all_ data gets passed to _all_ users.
// Besides being bad for performance, this also leaks any sensitive data.
//
// Reference: https://tanstack.com/query/v5/docs/react/guides/ssr

export const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // staleTime: 1 * 400, // 1 seconds of stale time in case of multiple query calls in a row, refetching // WARNING: this can lead to inconsistent result if you forget to add stale time to 0 on rapid queries. Cypress also will have issue while testing
        refetchOnWindowFocus: false, // do not refetch on window focus as default, can be overridden on a per-query basis
        retry: (failureCount, error) => {
          if (failureCount > 5) {
            return false;
          }

          if (error && isAxiosError(error)) {
            const status = error?.response?.status ?? 0;
            if ([401, 403, 404].includes(status)) {
              return false;
            }
          }

          return true;
        },
      },
    },
  });
};

export const getSSRQueryClient = (ssrHost: string | undefined) => {
  const { baseUrl } = getBaseUrl({ ssrHost });
  setBaseUrl(axiosInstance, baseUrl);

  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
      },
    },
  });
};
