import { ExperimentConfigurations } from './Experiment';

export enum ExperimentGroup {
  Control,
  Variant1,
  Variant2,
}

export enum ExperimentSelector {
  LanguageHeaderMenu,
}

export const experimentConfigurations: ExperimentConfigurations = {
  [ExperimentSelector.LanguageHeaderMenu]: {
    experimentSelector: ExperimentSelector.LanguageHeaderMenu,
    experimentId: 'AgZn3Q-4SUOB4QnWVsvxGg',
    // experimentGroups: {
    //   [ExperimentGroup.Control]: { percent: 50 },
    //   [ExperimentGroup.Variant1]: { percent: 50 },
    // },
  },
};
