import { SearchIcon } from '@ae/icons';
import { useRef, useState, useMemo } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  Text,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Grid,
  GridItem,
  VStack,
} from '@chakra-ui/react';
import { UIButton, useViewport, UIModal } from '@ae/shared-ui';
import {
  useApiLocalitiesGetCollection,
  useApiStayDateAvailablesGetCollection,
} from '@ae/data-access';
import { TFunction } from 'i18next';
import { useTranslation } from '@ae/shared';
import {
  Composition,
  defaultSearchValues,
  StayDate,
  useSearch,
} from '@ae/shared-comp';
import {
  defaultCompositionLabel,
  defaultLocalityLabel,
  defaultStayDateLabel,
  useSearchLabel,
} from './useSearchLabels';
import { CalendarPicker } from '../CalendarPicker';
import { CompositionPicker } from '../CompositionPicker/CompositionPicker';
import { LocalityPicker } from '../LocalityPicker/LocalityPicker';
import SearchField from './SearchField';
import { ConfirmButtons } from './ConfirmButtons';
import { useLanguage, Locale } from '@ae/i18n';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

enum Tabs {
  Search,
  Composition,
  Date,
  Locality,
}

const modalTitle = (t: TFunction<any, any>, selectedTab: Tabs) => {
  switch (selectedTab) {
    case Tabs.Search:
      return t('mr1.search_title');
    case Tabs.Composition:
      return t('mr1.composition.selection_cta');
    case Tabs.Date:
      return t('mr1.date.selection_cta');
    case Tabs.Locality:
      return t('mr1.locality.cta');
    default:
      return '';
  }
};

export const Mr1 = ({ ...props }: BoxProps) => {
  // Refs
  const popoverRef = useRef<HTMLDivElement>(null);
  const localityPopover = useRef<{ focus: () => void }>(null);
  // UI states
  const { MobileAndTablet, Desktop } = useViewport();
  const [selectedTab, setSelectedTab] = useState(Tabs.Search);
  const {
    isOpen: isCompositionOpen,
    onOpen: onCompositionOpen,
    onClose: onCompositionClose,
  } = useDisclosure();
  const {
    isOpen: isCalendarOpen,
    onOpen: onCalendarOpen,
    onClose: onCalendarClose,
  } = useDisclosure();
  const {
    isOpen: isLocalityOpen,
    onOpen: onLocalityOpen,
    onClose: onLocalityClose,
  } = useDisclosure();
  const {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onClose: onSearchClose,
  } = useDisclosure();
  // Data states
  const { data: stayDates } = useApiStayDateAvailablesGetCollection();
  const { data: localities } = useApiLocalitiesGetCollection();
  // Languages states
  const { t } = useTranslation('mr');
  const language = useLanguage();
  // Search states
  const { searchValues, updateSearch, makeSearch } = useSearch({
    localities: localities?.['hydra:member'],
  });
  const { composition, stayDate, localityId } = searchValues;
  const { getCompositionLabel, getStayDateLabel, getLocalityLabel } =
    useSearchLabel({
      localities: localities?.['hydra:member'],
    });

  // Labels
  const compositionLabel = useMemo(
    () =>
      composition
        ? getCompositionLabel(composition)
        : t(defaultCompositionLabel),
    [composition, getCompositionLabel, t]
  );
  const stayDateLabel = useMemo(
    () =>
      stayDate
        ? getStayDateLabel(stayDate, 'week-end', language as Locale)
        : t(defaultStayDateLabel),
    [stayDate, getStayDateLabel, language, t]
  );
  const localityLabel = useMemo(
    () => (localityId ? getLocalityLabel(localityId) : t(defaultLocalityLabel)),
    [localityId, getLocalityLabel, t]
  );
  const searchLabel = useMemo(() => {
    let label = getCompositionLabel(composition, '2-60 pers.');
    label += `, ${getStayDateLabel(stayDate, 'week-end', language as Locale)}`;
    label += `, ${getLocalityLabel(localityId, t('mr1.search.in_ardennes'))}`;
    return label;
  }, [
    getCompositionLabel,
    composition,
    getStayDateLabel,
    language,
    stayDate,
    getLocalityLabel,
    localityId,
    t,
  ]);

  const stayDatesWithoutTimezone = useMemo(
    () =>
      stayDates?.['hydra:member']?.map((d) => ({
        ...d,
        date: {
          ...d.date,
          startDate: format(
            utcToZonedTime(new Date(d.date.startDate), 'Europe/Brussels'),
            'yyyy-MM-dd'
          ),
          endDate: format(
            utcToZonedTime(new Date(d.date.endDate), 'Europe/Brussels'),
            'yyyy-MM-dd'
          ),
        },
      })) ?? [],
    [stayDates]
  );

  const onCompositionChange = (value: Composition | null) =>
    updateSearch({ ...searchValues, composition: value });

  const onStayDateChange = (value: StayDate | null) =>
    updateSearch({ ...searchValues, stayDate: value });

  const onLocalityChange = (value: number | null) =>
    updateSearch({ ...searchValues, localityId: value });

  return (
    <>
      <MobileAndTablet>
        <Flex
          borderRadius="20px"
          boxShadow="0 4px 30px 10px rgba(0,0,0,.05)"
          bgColor="white"
          h="62px"
          px="24px"
          justifyContent="space-between"
          alignItems="center"
          cursor="pointer"
          color="ae.green"
          onClick={() => onSearchOpen()}
        >
          <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {searchLabel}
          </Text>
          <SearchIcon ml="12px" size="18px" />
        </Flex>

        <UIModal
          title={modalTitle(t, selectedTab)}
          isOpen={isSearchOpen}
          onClose={() => {
            onSearchClose();
            setSelectedTab(Tabs.Search);
          }}
          showCloseButton={selectedTab === Tabs.Search}
          onBackClick={() => setSelectedTab(Tabs.Search)}
          pb="20px"
        >
          {selectedTab === Tabs.Search && (
            <VStack spacing="15px">
              <SearchField
                label={compositionLabel}
                showReset={composition !== null}
                onClick={() => setSelectedTab(Tabs.Composition)}
                onReset={() =>
                  onCompositionChange(defaultSearchValues.composition)
                }
                eventLabelSuffix="group"
              />
              <SearchField
                label={stayDateLabel}
                showReset={stayDate !== defaultSearchValues.stayDate}
                onClick={() => setSelectedTab(Tabs.Date)}
                onReset={() => onStayDateChange(defaultSearchValues.stayDate)}
                eventLabelSuffix="dates"
              />
              <SearchField
                label={localityLabel}
                showReset={localityId !== defaultSearchValues.localityId}
                onClick={() => {
                  localityPopover.current?.focus();
                  setSelectedTab(Tabs.Locality);
                }}
                onReset={() => onLocalityChange(defaultSearchValues.localityId)}
                eventLabelSuffix="localities"
              />
            </VStack>
          )}
          {selectedTab === Tabs.Composition && (
            <CompositionPicker
              value={composition}
              onChange={onCompositionChange}
              bottomComponent={
                <ConfirmButtons
                  onReset={() => {
                    onCompositionChange(defaultSearchValues.composition);
                    setSelectedTab(Tabs.Search);
                  }}
                  onConfirm={() => setSelectedTab(Tabs.Search)}
                />
              }
            />
          )}
          {selectedTab === Tabs.Date && (
            <CalendarPicker
              value={stayDate}
              stayDates={stayDatesWithoutTimezone}
              onChange={onStayDateChange}
              focusCalendarPopover={() => popoverRef.current?.focus()}
              bottomComponent={
                <ConfirmButtons
                  onReset={() => {
                    onStayDateChange(defaultSearchValues.stayDate);
                    setSelectedTab(Tabs.Search);
                  }}
                  onConfirm={() => setSelectedTab(Tabs.Search)}
                />
              }
            />
          )}
          {selectedTab === Tabs.Locality && (
            <LocalityPicker
              ref={localityPopover}
              localities={localities?.['hydra:member'] ?? []}
              onChange={(l) => {
                onLocalityChange(l?.id ?? null);
                setSelectedTab(Tabs.Search);
              }}
              onReset={() => {
                onLocalityChange(defaultSearchValues.localityId);
                setSelectedTab(Tabs.Search);
              }}
              bottomComponent={
                <ConfirmButtons
                  onReset={() => {
                    onLocalityChange(defaultSearchValues.localityId);
                    setSelectedTab(Tabs.Search);
                  }}
                  onConfirm={() => setSelectedTab(Tabs.Search)}
                />
              }
            />
          )}
          {selectedTab === Tabs.Search && (
            <Flex mt="40px" justifyContent="flex-end">
              <UIButton
                maxW="50%"
                h="100%"
                label={t('mr1.search_cta')}
                onClick={makeSearch}
              />
            </Flex>
          )}
        </UIModal>
      </MobileAndTablet>

      <Desktop>
        <Box
          h="85px"
          borderRadius="20px"
          boxShadow="lg"
          bgColor="white"
          display="flex"
          alignItems="center"
          px="20px"
          py="15px"
          {...props}
        >
          <Flex gap="20px" h="100%" w="100%">
            <Flex flexGrow={2}>
              <Grid
                templateColumns="repeat(3, minmax(0, 1fr))"
                gap="20px"
                w="100%"
              >
                <GridItem>
                  <Popover
                    isOpen={isCompositionOpen}
                    onOpen={onCompositionOpen}
                    onClose={onCompositionClose}
                    isLazy
                  >
                    <PopoverTrigger>
                      <SearchField
                        dataTestId="composition-search-field"
                        ariaLabel={t('mr1.open_composition_aria')}
                        label={compositionLabel}
                        showReset={
                          composition !== defaultSearchValues.composition
                        }
                        onClick={() => {
                          onCalendarClose();
                          onLocalityClose();
                        }}
                        onReset={() =>
                          onCompositionChange(defaultSearchValues.composition)
                        }
                        eventLabelSuffix="group"
                      />
                    </PopoverTrigger>

                    <PopoverContent
                      borderRadius="20px"
                      boxShadow="2xl"
                      bgColor="white"
                      display="flex"
                      alignItems="center"
                      px="20px"
                      py="15px"
                      w="370px"
                    >
                      <CompositionPicker
                        value={composition}
                        onChange={onCompositionChange}
                        bottomComponent={
                          <ConfirmButtons
                            onReset={() => {
                              onCompositionChange(
                                defaultSearchValues.composition
                              );
                              onCompositionClose();
                            }}
                            onConfirm={() => {
                              onCompositionClose();
                              onCalendarOpen();
                            }}
                          />
                        }
                      />
                    </PopoverContent>
                  </Popover>
                </GridItem>

                <GridItem>
                  <Popover
                    isOpen={isCalendarOpen}
                    onOpen={onCalendarOpen}
                    onClose={onCalendarClose}
                    isLazy
                  >
                    <PopoverTrigger>
                      <SearchField
                        dataTestId="available-stays-search-field"
                        ariaLabel={t('mr1.open_available_stays_aria')}
                        label={stayDateLabel}
                        showReset={stayDate !== defaultSearchValues.stayDate}
                        onClick={() => {
                          onCompositionClose();
                          onLocalityClose();
                        }}
                        onReset={() =>
                          onStayDateChange(defaultSearchValues.stayDate)
                        }
                        eventLabelSuffix="dates"
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      borderRadius="20px"
                      boxShadow="2xl"
                      bgColor="white"
                      display="flex"
                      alignItems="center"
                      px="20px"
                      py="15px"
                      w="940px"
                      ref={popoverRef}
                    >
                      <CalendarPicker
                        value={stayDate}
                        stayDates={stayDatesWithoutTimezone}
                        onChange={onStayDateChange}
                        focusCalendarPopover={() => popoverRef.current?.focus()}
                        bottomComponent={
                          <ConfirmButtons
                            onReset={() => {
                              onStayDateChange(defaultSearchValues.stayDate);
                              onCalendarClose();
                            }}
                            onConfirm={() => {
                              onCalendarClose();
                              onLocalityOpen();
                            }}
                          />
                        }
                      />
                    </PopoverContent>
                  </Popover>
                </GridItem>

                <GridItem>
                  <Popover
                    isOpen={isLocalityOpen}
                    onOpen={onLocalityOpen}
                    onClose={onLocalityClose}
                    initialFocusRef={localityPopover}
                    isLazy
                  >
                    <PopoverTrigger>
                      <SearchField
                        dataTestId="localities-search-field"
                        ariaLabel={t('mr1.open_localities_aria')}
                        label={localityLabel}
                        showReset={
                          localityId !== defaultSearchValues.localityId
                        }
                        onClick={() => {
                          onCompositionClose();
                          onCalendarClose();
                        }}
                        onReset={() =>
                          onLocalityChange(defaultSearchValues.localityId)
                        }
                        eventLabelSuffix="localities"
                      />
                    </PopoverTrigger>

                    <PopoverContent
                      borderRadius="20px"
                      boxShadow="2xl"
                      bgColor="white"
                      display="flex"
                      alignItems="center"
                      px="20px"
                      py="15px"
                      w="270px"
                    >
                      <LocalityPicker
                        ref={localityPopover}
                        localities={localities?.['hydra:member'] ?? []}
                        onChange={(l) => {
                          onLocalityChange(l?.id ?? null);
                          onLocalityClose();
                        }}
                        showOverallInArdeneButton
                        onReset={() => {
                          onLocalityChange(defaultSearchValues.localityId);
                          onLocalityClose();
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>
            </Flex>

            <UIButton
              h="100%"
              w="155px"
              flexShrink={0}
              label={t('mr1.search_cta')}
              onClick={makeSearch}
            />
          </Flex>
        </Box>
      </Desktop>
    </>
  );
};
