import { useContext } from 'react';
import { ApiContext } from '../providers';
import { getLocaleFromDomain } from './getLocaleFromDomain';
import { beProdDomains, foreignProdDomains } from './types';

export const isBeProdDomain = (host: string) =>
  Object.values(beProdDomains).some((domain) => host?.includes(domain));

export const isForeignProdDomain = (host: string): boolean =>
  Object.values(foreignProdDomains).some((domain) => host?.includes(domain));

export const isProdDomain = (host: string) =>
  isBeProdDomain(host) || isForeignProdDomain(host);

export const getProdDomains = (host: string = getHostFromBrowserOrParam()) =>
  isForeignProdDomain(host) ? foreignProdDomains : beProdDomains;

export const getHostFromBrowserOrParam = (host: string = '') =>
  // TODO: we should probably get rid of this.. to use a more SSR friendly approach (see hook useIsClient)
  typeof window !== 'undefined'
    ? `${window.location.protocol}//${window.location.host}`
    : host;

export const prodDomains = getProdDomains(getHostFromBrowserOrParam());

export const useApiDomain = (host: string = getHostFromBrowserOrParam()) => {
  const { baseApi, apiDomain } = useContext(ApiContext);
  return `${
    apiDomain ?? getProdDomains(host)[getLocaleFromDomain(host)]
  }${baseApi}`;
};
