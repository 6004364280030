import { useEffect, useState } from 'react';

// Note: This hook is used to check if the code is running on the client or server side.
// This is useful to avoid hydratation issues with SSR and allow to replace typeof window !== 'undefined' with a hook.
// docs: https://www.joshwcomeau.com/react/the-perils-of-rehydration/

export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
};
