import {
  RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental,
  useApiFavoritesListListIdfavoritesGetCollection,
  useApiFavoritesListListIdfavoritesIdDelete,
  useApiFavoritesListListIdfavoritesPost,
  useApiRentalsIdGet,
} from '@ae/data-access';
import {
  apiUtils,
  getFromLocalStorage,
  getOwnerFromAeReference,
  useTranslation,
} from '@ae/shared';
import { useTag } from '@ae/tagging';
import { Box, Flex, HStack, SkeletonText, Text } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ListRentalFavoriteListsContext } from '../RentalFavoriteList/ListRentalFavoriteListsContext';
import { UICheckbox } from '@ae/shared-ui';
import { useRentalFavoritesTracking } from '../RentalFavoritesTrackingContext';

export const SkeletonRentalFavoriteListItem = () => (
  <HStack spacing="10px" py="10px">
    <SkeletonText w="24px" noOfLines={1} skeletonHeight="24px" />
    <SkeletonText w="50%" noOfLines={1} skeletonHeight="24px" />
  </HStack>
);

type Props = {
  favoriteList: RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental;
  isChecked: boolean | undefined;
  onIsCheckedChange: () => void;
  rentalId: string | undefined;
};

const RentalFavoriteListItem = ({
  favoriteList,
  isChecked,
  onIsCheckedChange,
  rentalId,
}: Props) => {
  const { setOngoingMutations } = useContext(ListRentalFavoriteListsContext);
  const trackEvent = useTag();
  const { t } = useTranslation();
  const [value, setValue] = useState(isChecked);
  const [pendingNewValue, setPendingNewValue] = useState<boolean | null>(null);
  const { data: rental } = useApiRentalsIdGet(rentalId ?? '');
  const { listIndex, listName, listId } = useRentalFavoritesTracking();

  // We need to fetch this api endpoint to get the favorite uuid to delete
  const {
    data: rentalFavorites,
    isFetching: isRentalFavoritesLoading,
    refetch: refetchFavorites,
  } = useApiFavoritesListListIdfavoritesGetCollection(favoriteList.id ?? '', {
    pagination: false, // pagination is not working
    itemsPerPage: 10000,
  });

  const rentalGa4 = {
    item_name: `[${rental?.reference}]`,
    item_id: `[${rental?.reference}]`,
    item_location: rental?.region,
    item_category: rental?.theme,
    item_category2: rental?.capacity,
    item_category3: rental?.comfort,
    item_category4: rental?.averageNotation,
    product_owner_id: getOwnerFromAeReference(rental?.reference ?? ''),
    ...(getFromLocalStorage('ContextualDataLayerGA4') || {}),
  };

  if (listName) rentalGa4.item_list_name = listName;
  if (listId) rentalGa4.item_list_id = listId;
  if (listIndex) rentalGa4.index = listIndex;

  const { mutate: addToFavoritesList } = useApiFavoritesListListIdfavoritesPost(
    {
      mutation: {
        onSuccess: () => {
          onIsCheckedChange();
          refetchFavorites({ cancelRefetch: true });
        },
        onError: () => setValue(isChecked),
        onSettled: () => {
          trackEvent({
            ga4: {
              event: 'add_to_wishlist',
              ...rentalGa4,
            },
          });
          setOngoingMutations(favoriteList.id ?? '', false);
        },
      },
    }
  );

  const { mutate: removeFromFavoritesList } =
    useApiFavoritesListListIdfavoritesIdDelete({
      mutation: {
        onSuccess: () => {
          onIsCheckedChange();
          refetchFavorites({ cancelRefetch: true });
        },
        onError: () => setValue(isChecked),
        onSettled: () => {
          trackEvent({
            ga4: {
              event: 'remove_from_wishlist',
              ...rentalGa4,
            },
          });
          setOngoingMutations(favoriteList.id ?? '', false);
        },
      },
    });

  const changeValue = useCallback(
    (newValue: boolean) => {
      if (newValue) {
        addToFavoritesList({
          listId: favoriteList.id ?? '',
          data: {
            rentalId: Number(rentalId ?? 0),
          },
        });
      } else {
        const favoriteId = rentalFavorites?.['hydra:member']?.find(
          (favorite) =>
            String(apiUtils.fromIriToId(favorite.rental)) === rentalId
        )?.id;

        if (favoriteId) {
          removeFromFavoritesList({
            id: String(favoriteId ?? ''),
            listId: favoriteList.id ?? '',
          });
        } else {
          return;
        }
      }

      setValue(newValue);
      setOngoingMutations(favoriteList.id ?? '', true);
    },
    [
      addToFavoritesList,
      favoriteList.id,
      removeFromFavoritesList,
      rentalFavorites,
      rentalId,
      setOngoingMutations,
    ]
  );

  useEffect(() => {
    if (pendingNewValue !== null && !isRentalFavoritesLoading) {
      changeValue(pendingNewValue);
      setPendingNewValue(null);
    }
  }, [changeValue, isRentalFavoritesLoading, pendingNewValue]);

  const onCheckboxChange = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isRentalFavoritesLoading) {
      setPendingNewValue(!value);
      return;
    }

    changeValue(!value);
    setOngoingMutations(favoriteList.id ?? '', true);
  };

  return (
    <Flex
      py="10px"
      flexDir="row"
      alignItems="center"
      data-testid="add-rental-to-favorite-list-item"
      cursor="pointer"
      aria-label={t('favorites.list.append_rental_aria')}
      onClick={onCheckboxChange}
    >
      <Box ml="7px" mr="15px" w="20px">
        <UICheckbox isChecked={value} />
      </Box>
      <Text color="ae.green">{favoriteList.name}</Text>
    </Flex>
  );
};

export default RentalFavoriteListItem;
