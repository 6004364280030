export const enum Locale {
  Nl = 'nl',
  Fr = 'fr',
  En = 'en',
  De = 'de',
}

export const locales = [Locale.De, Locale.Nl, Locale.Fr, Locale.En];

export const defaultLocale = Locale.Nl;

export const beProdDomains = {
  nl: 'https://www.ardennes-etape.be',
  fr: 'https://fr.ardennes-etape.be',
  en: 'https://en.ardennes-etape.be',
  de: 'https://de.ardennes-etape.be',
};

export const foreignProdDomains = {
  nl: 'https://www.ardennes-etape.nl',
  fr: 'https://www.ardennes-etape.fr',
  en: 'https://www.ardennes-etape.co.uk',
  de: 'https://www.ardennes-etape.de',
};
