export const isValidAeReference = (aeReference: string): boolean => {
  return /^[0-9]{6}-[0-9]{2}$/.test(aeReference);
};

export const getOwnerFromAeReference = (aeReference: string): string => {
  if (!isValidAeReference(aeReference)) {
    return '';
  }
  return aeReference.substr(0, 6);
};
