export const badgeTheme = {
  baseStyle: {
    color: 'white',
    borderRadius: '4px',
    p: '9px 8px 9px 8px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  variants: {
    earlyBooking: {
      bgColor: 'ae.orange',
    },
    new: {
      bgColor: 'ae.purple',
    },
    promo: {
      bgColor: 'ae.orange',
    },
  },
};
