import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Locale } from '@ae/i18n';
import { localeToI18nLocale } from '../utils';

export const formatToBrusselsTz = (
  date: Date,
  format_template: string,
  locale: Locale
): string =>
  format(utcToZonedTime(date, 'Europe/Brussels'), format_template, {
    locale: localeToI18nLocale(locale),
  });
