import { Locale } from '@ae/i18n';
import { de, enUS, fr, nlBE } from 'date-fns/locale';

export const localeToI18nLocale = (locale: Locale) => {
  switch (locale) {
    case Locale.En:
      return enUS;
    case Locale.Fr:
      return fr;
    case Locale.De:
      return de;
    case Locale.Nl:
    default:
      return nlBE;
  }
};
