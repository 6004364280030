import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
  withBackground?: boolean;
};

export const PageContainer = ({
  withBackground = false,
  children,
  ...props
}: Props & ContainerProps) => {
  let backgroundColor = 'none';
  if (withBackground) {
    backgroundColor = '#fff';
  }
  return (
    <Container
      maxW="container.xl"
      height="100%"
      px={{ base: 0, md: 6, lg: 4 }}
      background={backgroundColor}
      {...props}
    >
      {children}
    </Container>
  );
};
