import { Box } from '@chakra-ui/react';
import { CreateRentalFavoriteListForm } from './CreateRentalFavoriteListForm';

type Props = {
  onSuccess: () => void;
};

export const CreateRentalFavoriteListTab = ({ onSuccess }: Props) => {
  return (
    <Box data-testid="create-favorites-list-tab">
      <CreateRentalFavoriteListForm onSuccess={onSuccess} />
    </Box>
  );
};
