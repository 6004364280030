export enum AEXSection {
  Experience = 'experience',
  Walk = 'walk',
}

export enum AEXCategory {
  Culture = 'culture',
  Nature = 'nature',
  Sport = 'sport',
  Terroir = 'terroir',
}

export const AEXCategoryIds = {
  [AEXCategory.Culture]: 85,
  [AEXCategory.Nature]: 2,
  [AEXCategory.Sport]: 14,
  [AEXCategory.Terroir]: 1,
};

const sectionPath = (section: AEXSection, lng: string): string => {
  if (section === AEXSection.Experience) {
    switch (lng) {
      case 'de':
      case 'en':
        return 'search';
      case 'fr':
        return 'recherche';
      case 'nl':
      default:
        return 'zoek';
    }
  } else if (section === AEXSection.Walk) {
    switch (lng) {
      case 'de':
      case 'en':
        return 'hikes';
      case 'fr':
        return 'balades';
      case 'nl':
      default:
        return 'wandelingen';
    }
  }
  return '';
};

export const useBuildAEXUrl = () => {
  const buildUrl = ({
    domain,
    lng,
    lat,
    long,
    rate,
    section,
    category,
  }: {
    domain: string;
    lng: string;
    lat?: string;
    long?: string;
    rate?: number;
    section: AEXSection;
    category?: AEXCategory;
  }): string => {
    const path = `${domain}/experience/${sectionPath(section, lng)}`;

    const params = new URLSearchParams();
    if (rate) {
      params.set('rate', String(rate));
    }
    if (category) {
      params.set('field_category_target_id', String(AEXCategoryIds[category]));
    }
    if (lat && long) {
      params.set('latlon', `${lat},${long}`);
    }

    if (params.toString() === '') {
      return path;
    }
    return `${path}?${params}`;
  };

  return { buildUrl };
};
