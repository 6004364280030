import { ExternalLink, useGetExternalLinks, useTranslation } from '@ae/shared';
import { HStack, Link, Img } from '@chakra-ui/react';
import Title from './Title';

const BookInSecuritySection = () => {
  const { t } = useTranslation('footer');
  const { getLinkProps, getLinkAlt, getLinkImage } = useGetExternalLinks();

  const securityLinkWidths: { [x: string]: string } = {
    [ExternalLink.Becommerce]: '50px',
    [ExternalLink.ECommerceEurope]: '65px',
  };

  return (
    <>
      <Title>{t('footer.book_in_security')}</Title>
      <HStack spacing="30px">
        {[ExternalLink.Becommerce, ExternalLink.ECommerceEurope].map((link) => (
          <Link
            {...getLinkProps(link)}
            bgColor="white"
            w={securityLinkWidths[link]}
            h="50px"
            p="5px"
          >
            <Img
              objectFit="cover"
              alt={getLinkAlt(link)}
              src={getLinkImage(link)}
              loading="lazy"
            />
          </Link>
        ))}
      </HStack>
    </>
  );
};

export default BookInSecuritySection;
