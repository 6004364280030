import { getBaseUrl } from '@ae/i18n';
import { AxiosInstance } from 'axios';

import { createContext } from 'react';

export interface ApiContextState {
  baseApi: string;
  apiDomain?: string;
}

export const ApiContext = createContext<ApiContextState>({
  baseApi: '',
});

export const ApiProvider = ({
  baseApi: overridedBaseApi,
  apiDomain: overridedApiDomain,
  axiosInstance,
  children,
}: {
  baseApi?: string | undefined;
  apiDomain?: string | undefined;
  axiosInstance: AxiosInstance;
  children: React.ReactElement;
}) => {
  const { baseUrl, apiDomain, baseApi } = getBaseUrl({
    overridedApiDomain,
    overridedBaseApi,
  });

  axiosInstance.defaults.baseURL = baseUrl;

  return (
    <ApiContext.Provider value={{ apiDomain, baseApi }}>
      {children}
    </ApiContext.Provider>
  );
};
