import { UserLoginChangeParams } from '@ae/shared-comp';
import { KeycloakProps } from '@ae/auth';
import { supportedLngs } from '@ae/i18n';

declare global {
  interface Window {
    AEComponentProps: StandaloneComponentProps;
  }
}

type OnLocaleChange = (locale: string) => void | undefined;
type OnUserLoginChange = (userData: UserLoginChangeParams) => void | undefined;

export type StandaloneComponentProps = {
  supportedLngs: string[];
  onLocaleChange?: OnLocaleChange;
  onUserLoginChange: OnUserLoginChange;
  assetsPath?: string;
  basePath?: string;
  apiDomain?: string;
  baseApi?: string;
  keycloak?: KeycloakProps;

  customer: {
    customerBasePath?: string;
    customerDomain?: string;
  };
  header: {
    showMr1?: boolean;
    hideBackground?: boolean;
    showTopBar?: boolean;
  };
  footer: {
    hideHelpCenter: false;
  };
};

export const defaultStandaloneComponentProps = {
  assetsPath: '',
  basePath: '',
  apiDomain: undefined,
  baseApi: undefined,
  supportedLngs: supportedLngs,
  keycloak: undefined,
  onLocaleChange: undefined,
  customer: {
    customerBasePath: '/guest',
    customerDomain: undefined,
  },
  header: {
    showMr1: false,
    hideBackground: false,
    showTopBar: false,
  },
  footer: {
    hideHelpCenter: false,
  },
};

export const standaloneComponentProps = {
  ...defaultStandaloneComponentProps,
  ...window.AEComponentProps,
};
