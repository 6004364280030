/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext } from 'react';
import {
  useTranslation as useNextTranslation,
  Trans as NextTrans,
} from 'next-i18next';
import {
  UseTranslationResponse as UseReactTranslationResponse,
  Trans as ReactTrans,
  useTranslation as useReactTranslation,
} from 'react-i18next';

type UseTranslationType =
  | typeof useNextTranslation
  | typeof useReactTranslation;

type TransType = typeof NextTrans | typeof ReactTrans;

type UseTranslationResponse = UseReactTranslationResponse<any, any>;

interface I18nContextState {
  useTranslation: UseTranslationType;
  Trans: TransType;
}

const I18nContext = createContext<I18nContextState>({
  useTranslation: useReactTranslation,
  Trans: ReactTrans,
});

export const useTranslation = (...props: any): UseTranslationResponse => {
  const { useTranslation } = useContext(I18nContext);
  return useTranslation(...props);
};

export const useTrans = (): TransType => {
  const { Trans } = useContext(I18nContext);
  return Trans;
};

interface I18nProviderProps {
  useTranslation: UseTranslationType;
  Trans: TransType;
  children: React.ReactNode;
}

export const I18nProvider = ({
  useTranslation,
  Trans,
  children,
}: I18nProviderProps) => {
  return (
    <I18nContext.Provider value={{ useTranslation, Trans }}>
      {children}
    </I18nContext.Provider>
  );
};
