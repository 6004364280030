import React, { useCallback, useContext } from 'react';
import { GlobalTagContext } from './TagProvider';
import { DataLayers } from './types';
import { TagElementContext, useTag } from './useTag';

type Props = {
  children: React.ReactElement;
};

export const TagElement = ({ children, ua, ga4 }: DataLayers & Props) => {
  const data = useContext(GlobalTagContext);
  const baseTrackEvent = useTag();

  const trackEvent = useCallback(
    (additionalData?: DataLayers) => {
      baseTrackEvent({
        ua: {
          eventCategory: data?.website_section,
          ...ua,
          ...additionalData?.ua,
        },
        ga4: {
          ...data,
          ...ga4,
          ...additionalData?.ga4,
        },
      });
    },
    [baseTrackEvent, data, ga4, ua]
  );

  return (
    <TagElementContext.Provider value={{ trackEvent }}>
      {React.cloneElement(children)}
    </TagElementContext.Provider>
  );
};
