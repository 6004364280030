import { Box, BoxProps } from '@chakra-ui/react';

export type SeparatorProps = {
  color?: string;
  size?: string;
} & BoxProps;

export const UISeparator = ({
  color = 'ae.grey_100',
  size = '1px',
  ...otherProps
}: SeparatorProps) => {
  return (
    <Box w="full" height={size} bgColor={color} {...otherProps} border="none" />
  );
};
