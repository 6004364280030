import { UIModal } from '@ae/shared-ui';
import { useState, useEffect } from 'react';
import { ListRentalFavoriteListsContextProvider } from './ListRentalFavoriteListsContext';
import { CreateRentalFavoriteListTab } from './CreateRentalFavoriteListTab';
import { ListRentalFavoriteListsTab } from './ListRentalFavoriteListsTab';
import { useTranslation } from '@ae/shared';

export enum Tabs {
  ListRentalFavoriteLists = 1, // start at 0 will cause overlapping tabs...
  CreateFavoritesList = 2,
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  rentalId: string;
  onFavoriteChange?: () => void;
};

export const AddRentalToFavoriteListModal = ({
  isOpen,
  onClose: onBaseClose,
  rentalId,
  onFavoriteChange,
}: Props) => {
  const { t } = useTranslation(['favorites']);
  const [ongoingMutations, setOngoingMutations] = useState<string[]>([]);
  const [waitingClosing, setWaitingClosing] = useState(false);
  const [selectedTab, setSelectedTab] = useState(Tabs.ListRentalFavoriteLists);

  const onClose = () => {
    if (ongoingMutations.length) {
      setWaitingClosing(true);
    } else {
      selectedTab === Tabs.CreateFavoritesList
        ? setSelectedTab(Tabs.ListRentalFavoriteLists)
        : onBaseClose();
    }
  };

  useEffect(() => {
    if (waitingClosing && ongoingMutations.length === 0) {
      setWaitingClosing(false);
      onBaseClose();
    }
  }, [onBaseClose, ongoingMutations.length, selectedTab, waitingClosing]);

  const title = () => {
    switch (selectedTab) {
      case Tabs.ListRentalFavoriteLists:
        return t('favorites.list.append_location_cta');
      case Tabs.CreateFavoritesList:
        return t('favorites.list.creation_title');
      default:
        return '';
    }
  };

  const onCreateFavoriteListButtonClick = () =>
    setSelectedTab(Tabs.CreateFavoritesList);

  const onOngoingMutationsChange = (id: string, value: boolean) => {
    if (value) {
      setOngoingMutations((prevState) => [
        ...prevState.filter((el) => el !== id),
        id,
      ]);
    } else {
      setOngoingMutations((prevState) => [
        ...prevState.filter((el) => el !== id),
      ]);
    }
  };

  return (
    <UIModal
      isOpen={isOpen}
      onClose={onClose}
      title={title()}
      dataTestId="add-rental-to-favorites-list-modal"
    >
      <ListRentalFavoriteListsContextProvider
        setOngoingMutations={onOngoingMutationsChange}
      >
        {selectedTab === Tabs.ListRentalFavoriteLists && (
          <ListRentalFavoriteListsTab
            rentalId={rentalId}
            onConfirmationClick={onClose}
            onCreateFavoriteListButtonClick={onCreateFavoriteListButtonClick}
            onFavoriteChange={onFavoriteChange}
            waitingClosing={waitingClosing}
          />
        )}
      </ListRentalFavoriteListsContextProvider>

      {selectedTab === Tabs.CreateFavoritesList && (
        <CreateRentalFavoriteListTab
          onSuccess={() => setSelectedTab(Tabs.ListRentalFavoriteLists)}
        />
      )}
    </UIModal>
  );
};
