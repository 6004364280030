export enum WeekDays {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export const weekDaysTranslations: { [key in WeekDays]: string } = {
  [WeekDays.MONDAY]: 'monday',
  [WeekDays.TUESDAY]: 'tuesday',
  [WeekDays.WEDNESDAY]: 'wednesday',
  [WeekDays.THURSDAY]: 'thursday',
  [WeekDays.FRIDAY]: 'friday',
  [WeekDays.SATURDAY]: 'saturday',
  [WeekDays.SUNDAY]: 'sunday',
};

export const getWeekDaysArray = (): string[] => {
  return Object.values(WeekDays);
};

export const getTranslatedWeekDaysArray = (t: any): string[] => {
  return Object.values(WeekDays).map((day) => t(weekDaysTranslations[day]));
};

const weekdayOrder = Object.values(WeekDays);

export const sortWeekDays = (unorderedWeekDays: WeekDays[]) => {
  // we use [...] to be sure to return a new array (so we have correct re-renders and we don't have issue with read-only arrays)
  const sortedArray = [...unorderedWeekDays].sort(
    (a, b) => weekdayOrder.indexOf(a) - weekdayOrder.indexOf(b)
  );
  // we compare both array, as if they are the same, we do not return a new array to avoid unecessary re-renders
  if (JSON.stringify(unorderedWeekDays) === JSON.stringify(sortedArray))
    return unorderedWeekDays;

  return sortedArray;
};
