import React, { MouseEvent } from 'react';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { LinkWrapper } from '@ae/shared-ui';
import { useTrackEvent } from '@ae/tagging';

type Props = {
  href: string;
  target?: string;
  variant?: 'link' | 'text' | 'link-white';
} & LinkProps;

type Ref = HTMLAnchorElement;

export const Link = React.forwardRef<Ref, Props>(
  ({ href, target, variant = 'link', ...props }, ref) => {
    const trackEvent = useTrackEvent();

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
      trackEvent();
      if (props.onClick) {
        props.onClick(e);
      }
    };

    let styles = {};
    if (variant === 'text') {
      styles = {
        textDecoration: 'underline',
        fontWeight: 'bold',
        color: 'ae.green',
      };
    } else if (variant === 'link-white') {
      styles = {
        color: 'ae.white',
      };
    }

    return (
      <LinkWrapper href={href} target={target}>
        <ChakraLink
          ref={ref}
          onClick={handleClick}
          draggable={false}
          {...styles}
          {...props}
        />
      </LinkWrapper>
    );
  }
);
