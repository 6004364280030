import { StandaloneComponent } from './StandaloneComponent';
import { createRoot } from 'react-dom/client';
import { Header } from '@ae/shared-comp';
import { standaloneComponentProps } from '../types';

const HeaderStandaloneComponent = () => {
  const {
    onUserLoginChange,
    header: { hideBackground, showMr1, showTopBar },
  } = standaloneComponentProps;

  return (
    <StandaloneComponent appName="header">
      <Header
        hideBackground={hideBackground}
        showMr1={showMr1}
        showTopBar={showTopBar}
        onUserLoginStatusChange={onUserLoginChange}
      />
    </StandaloneComponent>
  );
};

const container = document.getElementById('header');

if (container) {
  const root = createRoot(container);
  root.render(<HeaderStandaloneComponent />);
}
