import { useLanguage } from '@ae/i18n';
import { Box, BoxProps, Circle } from '@chakra-ui/react';
import { CircleFlag } from 'react-circle-flags';

export const UICountryFlag = ({ ...props }: BoxProps) => {
  const language = useLanguage();

  const countryCode = () => {
    switch (language) {
      case 'fr':
      case 'nl':
        return 'be';
      case 'en':
        return 'gb';
      case 'de':
        return 'de';
      default:
        return '';
    }
  };

  return (
    <Box
      bgColor="white"
      borderRadius="50px"
      height="26px"
      width="26px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p="2px"
      {...props}
    >
      <Circle size="26px" p="2px" bgColor="white">
        <CircleFlag countryCode={countryCode()} />
      </Circle>
    </Box>
  );
};
