/* eslint-disable no-empty-pattern */
import { createContext, useContext, ReactElement, ReactNode } from 'react';
import { useMediaQuery } from '@mantine/hooks';

export interface MediaContextState {
  MobileAndTablet: ({
    children,
  }: {
    children: ReactNode;
  }) => ReactElement | null;
  Mobile: ({ children }: { children: ReactNode }) => ReactElement | null;
  Tablet: ({ children }: { children: ReactNode }) => ReactElement | null;
  Desktop: ({ children }: { children: ReactNode }) => ReactElement | null;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isMobileOrTablet: boolean;
}

export const MediaContext = createContext<MediaContextState>({
  MobileAndTablet: ({}) => <div />,
  Mobile: ({}) => <div />,
  Tablet: ({}) => <div />,
  Desktop: ({}) => <div />,
  isDesktop: false,
  isTablet: false,
  isMobile: false,
  isMobileOrTablet: false,
});

export const useViewport = () => {
  const {
    MobileAndTablet,
    Mobile,
    Tablet,
    Desktop,
    isMobile,
    isTablet,
    isMobileOrTablet,
    isDesktop,
  } = useContext(MediaContext);

  return {
    MobileAndTablet,
    Mobile,
    Tablet,
    Desktop,
    isMobile,
    isTablet,
    isMobileOrTablet,
    isDesktop,
  };
};

export const MediaProvider = ({
  children,
  userDevice,
}: {
  children: ReactNode;
  userDevice?: string;
}) => {
  const isMobile = useMediaQuery(
    'screen and (min-width: 0) and (max-width: 480px)',
    userDevice === 'mobile'
  );

  const isTablet = useMediaQuery(
    'screen and (min-width: 480px) and (max-width: 896px)',
    userDevice === 'tablet'
  );

  let isDesktop = useMediaQuery(
    'screen and (min-width: 896px)',
    userDevice === 'desktop'
  );

  const isMobileOrTablet = isMobile || isTablet;

  if (!isMobile && !isTablet) {
    isDesktop = true;
  }

  const Mobile = ({ children }: { children: ReactNode }) =>
    (isMobile ? children : null) as ReactElement | null;

  const Tablet = ({ children }: { children: ReactNode }) =>
    (isTablet ? children : null) as ReactElement | null;

  const MobileAndTablet = ({ children }: { children: ReactNode }) =>
    (isMobileOrTablet ? children : null) as ReactElement | null;

  const Desktop = ({ children }: { children: ReactNode }) =>
    (isDesktop ? children : null) as ReactElement | null;

  return (
    <MediaContext.Provider
      value={{
        MobileAndTablet,
        Mobile,
        Tablet,
        Desktop,
        isMobile,
        isTablet,
        isDesktop,
        isMobileOrTablet,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};
