import { useSocialLinks, SocialLink, useTranslation } from '@ae/shared';
import { UILinkIcon } from '@ae/shared-ui';
import { Box, HStack } from '@chakra-ui/react';
import Title from './Title';

const FollowUsSection = () => {
  const { t } = useTranslation('footer');
  const { getLinkProps, getLinkIcon } = useSocialLinks();

  return (
    <Box id="followUs">
      <Title>{t('footer.follow_us')}</Title>
      <HStack spacing="15px">
        {[
          SocialLink.Facebook,
          SocialLink.LinkedIn,
          SocialLink.Instagram,
          SocialLink.Youtube,
          SocialLink.Pinterest,
        ].map((link) => (
          <UILinkIcon
            variant="primary"
            w="40px"
            h="40px"
            {...getLinkProps(link)}
            icon={getLinkIcon(link)}
          />
        ))}
      </HStack>
    </Box>
  );
};

export default FollowUsSection;
