import { useState } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverTrigger,
  BoxProps,
  forwardRef,
  useMediaQuery,
  DrawerCloseButton,
  DrawerHeader,
  Text,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { DotsIcon } from '@ae/icons';
import { useTag } from '@ae/tagging';

export type CardDetailOverlayProps = {
  title: string;
  buttonLabel: string;
  children?: React.ReactNode;
  startOpen?: boolean;
};

const DotsButton = forwardRef<BoxProps & { label: string }, 'div'>(
  ({ label, ...props }, ref) => (
    <Box
      ref={ref}
      padding="5px 0px 5px 10px"
      display="flex"
      justifyContent="right"
      as="button"
      aria-label={label}
      {...props}
    >
      <DotsIcon />
    </Box>
  )
);

export const UICardDetailOverlay = ({
  title,
  buttonLabel,
  children,
  startOpen = false,
}: CardDetailOverlayProps) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [isDesktop] = useMediaQuery('(min-width: 768px)');
  const trackEvent = useTag();

  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    trackEvent({
      ua: {
        eventAction: 'my_holidays',
        eventLabel: 'settings',
      },
      ga4: {
        event: 'client_account_cta',
        website_subsection: 'holiday_section',
        category: 'reservations_settings',
        action: 'click',
      },
    });
    setIsOpen(true);
  };

  if (!isDesktop) {
    return (
      <>
        <DotsButton label={buttonLabel} onClick={onOpen} />
        <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent
            boxShadow={{ base: 'base', md: 'none' }}
            borderRadius={{ base: '20px 20px 0px 0px', md: '0' }}
            borderStyle={{ lg: 'solid' }}
            borderColor={{ lg: 'ae.grey_100' }}
            pb={8}
          >
            <DrawerCloseButton border={0} />
            <DrawerHeader maxW="calc( 100% - 40px)">
              <Box>
                <Text as="span" color="ae.green">
                  {title}
                </Text>
              </Box>
            </DrawerHeader>
            <DrawerBody>{children}</DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="right-start">
      <PopoverTrigger>
        <DotsButton label={buttonLabel} onClick={onOpen} />
      </PopoverTrigger>
      <PopoverContent
        background="#fff"
        borderStyle={{ base: 'none' }}
        borderRadius={{ base: '20px 20px 0 0' }}
        _active={{ border: 0 }}
      >
        <Box
          boxShadow={{ base: 'base' }}
          borderRadius={{ base: '20px 20px 0 0' }}
          p="20px 10px"
        >
          <PopoverCloseButton border={0} top="29px" right="20px" />
          <PopoverHeader borderBottomStyle="none" maxW="calc( 100% - 40px)">
            <Text
              pl={1}
              as="span"
              color="ae.green"
              fontWeight="bold"
              fontSize="18px"
            >
              {title}
            </Text>
          </PopoverHeader>
          <PopoverBody>{children}</PopoverBody>
        </Box>
      </PopoverContent>
    </Popover>
  );
};
