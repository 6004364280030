import { useApiCustomersIdGet, useApiUsersIdGet } from '@ae/data-access';
import { apiUtils } from '../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMe = (query: any = {}) => {
  const {
    data: user,
    isLoading: userLoading,
    isError: isUserError,
    error: userError,
  } = useApiUsersIdGet('me', { query: { staleTime: 2000, ...query } });

  const {
    data: customer,
    isLoading: customerLoading,
    isError: isCustomerError,
    error: customerError,
    ...other
  } = useApiCustomersIdGet(apiUtils.fromIriToId(user?.customer) || '', {
    query: { enabled: user?.customer !== undefined, staleTime: 2000 },
  });

  return {
    authenticated: user?.customer !== undefined,
    data: customer,
    isLoading: userLoading || customerLoading,
    isError: isUserError || isCustomerError,
    error: userError || customerError,
    ...other,
  };
};
