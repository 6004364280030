import React from 'react';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { InfoIcon, SelectIcon, ValidIcon, Warning2Icon } from '@ae/icons';

export enum AccordionStatus {
  TODO = 'TODO',
  INFORMATION = 'INFORMATION',
  DONE = 'DONE',
  BASE = 'BASE',
}

type Props = {
  children: React.ReactNode;
  title: string;
  status: AccordionStatus;
  id?: string;
  itemRef?: React.RefObject<HTMLDivElement>;
};

export const AccordionContent = ({
  children,
  title,
  status,
  id,
  itemRef,
}: Props) => {
  let icon: React.ReactElement | undefined;
  let color = 'black';
  let weight = '';
  let fontSize = '';

  switch (status) {
    case AccordionStatus.INFORMATION:
      icon = <InfoIcon space="18px" size="16px" />;
      color = 'ae.green';
      break;
    case AccordionStatus.TODO:
      icon = <Warning2Icon size="18px" space="16px" />;
      color = 'error.900';
      break;
    case AccordionStatus.BASE:
      color = 'ae.green';
      weight = '700';
      fontSize = '18px';
      break;
    default:
      icon = <ValidIcon space="18px" size="16px" />;
      color = 'ae.green';

      break;
  }

  return (
    <AccordionItem
      borderTop="1px solid ae.grey_100"
      borderBottom="0"
      shadow="none"
      id={id}
      ref={itemRef}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            border="0"
            paddingX="10px"
            paddingY="15px"
            alignItems="center"
            sx={{
              _hover: {
                bg: '',
              },
              _active: {
                bg: '',
              },
            }}
          >
            <Box
              flex="1"
              textAlign="left"
              color={color}
              fontWeight={weight}
              fontSize={fontSize}
            >
              {icon} {title}
            </Box>
            {isExpanded ? (
              <Box transform="rotate(180deg)">
                <SelectIcon size="32px" color="ae.green" />
              </Box>
            ) : (
              <SelectIcon size="32px" color="ae.green" />
            )}
          </AccordionButton>
          <AccordionPanel px="0">{children}</AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
