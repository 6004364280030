import {
  LanguageContext,
  Locale,
  beProdDomains,
  getHostFromBrowserOrParam,
  useApiDomain,
  getProdDomains,
} from '@ae/i18n';
import { useContext, useMemo, useCallback } from 'react';
import { RouterContext } from '../providers';

export const useGetDomain = () => {
  const { locale } = useContext(LanguageContext);
  const router = useContext(RouterContext);
  const apiDomain = useApiDomain();

  const prodDomains = useMemo(
    () => getProdDomains(getHostFromBrowserOrParam(router.host)),
    []
  );
  const prodDomain = useMemo(() => prodDomains[locale as Locale], [locale]);
  const getProdDomain = useCallback(
    (locale: Locale) => prodDomains[locale],
    []
  );

  const isBeProdDomain = useMemo(() => {
    return prodDomain?.includes('.be');
  }, [prodDomain]);

  const beProdDomain = useMemo(() => beProdDomains[locale as Locale], [locale]);
  const getBeProdDomain = useCallback(
    (locale: Locale) => beProdDomains[locale],
    []
  );

  const racineDomain = useMemo(() => {
    try {
      const url = new URL(getHostFromBrowserOrParam());
      url.port = '';
      return url.hostname.substring(url.hostname.indexOf('ardennes-etape'));
    } catch {
      return '';
    }
  }, []);

  const getRacineDomain = useCallback((domain: string) => {
    try {
      const url = new URL(domain);
      url.port = '';
      return `.${url.hostname.substring(
        url.hostname.indexOf('ardennes-etape')
      )}`;
    } catch {
      return '';
    }
  }, []);

  return {
    authDomain: apiDomain,
    apiDomain: apiDomain,
    prodDomain,
    getProdDomain,
    beProdDomain,
    getBeProdDomain,
    racineDomain,
    getRacineDomain,
    isBeProdDomain,
  };
};
