/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Ardennes étape -- API
 * OpenAPI spec version: 1.0.0
 */

export enum CalendarPeriodType {
  touristic_season = 'touristic_season',
  meteo_season = 'meteo_season',
  long_weekend = 'long_weekend',
  school_holiday = 'school_holiday',
  local_event = 'local_event',
  other = 'other',
}
