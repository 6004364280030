import { HStack, VStack } from '@chakra-ui/react';
import { FormEventHandler } from 'react';
import { UIButton } from '../../display/UIButton';

export const UIFormLayout = ({
  onSubmit,
  children,
}: {
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
  children: React.ReactNode;
}) => {
  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={8} alignItems="stretch">
        {children}
      </VStack>
    </form>
  );
};

const Fields = ({ children }: { children: React.ReactNode }) => {
  return (
    <VStack spacing={4} alignItems="stretch">
      {children}
    </VStack>
  );
};

const CancelConfirmButtons = ({
  onCancel,
  cancelLabel = 'Annuler',
  submitLabel = 'Valider',
}: {
  onCancel: () => void;
  cancelLabel?: string;
  submitLabel?: string;
}) => {
  return (
    <HStack>
      <UIButton onClick={onCancel} variant="tertiary">
        {cancelLabel}
      </UIButton>
      <UIButton type="submit">{submitLabel}</UIButton>
    </HStack>
  );
};

UIFormLayout.Fields = Fields;
UIFormLayout.CancelConfirmButtons = CancelConfirmButtons;

UIFormLayout.displayName = 'UIFormLayout';

Fields.displayName = 'UIFormLayout.Fields';
CancelConfirmButtons.displayName = 'UIFormLayout.CancelConfirmButtons';
