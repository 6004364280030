import { addDays, endOfMonth, isSameDay, isWithinInterval } from 'date-fns';
import { StayDate } from '@ae/shared-comp';
import { useGetStayDates, useSelectedStayDate } from '../stores';

enum Day {
  Yesterday = -1,
  Today = 0,
  Tomorrow = 1,
}

const getFirstOrNull = (intervals: StayDate[]) =>
  intervals.length ? intervals[0] : null;

const intervalsOnDay = (intervals: StayDate[], day: Date, relativeDay: Day) =>
  intervals.filter((interval) => {
    if (!interval?.startDate || !interval?.endDate) {
      return false;
    }
    return isWithinInterval(addDays(day, relativeDay), {
      start: interval.startDate,
      end: interval.endDate,
    });
  });

const isWithinSelectedInterval = (
  day: Date,
  selectedInterval: StayDate | null
) => {
  if (
    !selectedInterval ||
    !selectedInterval.startDate ||
    !selectedInterval.endDate
  )
    return false;
  return isWithinInterval(day, {
    start: selectedInterval.startDate,
    end: selectedInterval.endDate,
  });
};

const isFirstDayOfInterval = (interval: StayDate | null, day: Date) => {
  return interval?.startDate && isSameDay(interval.startDate, day);
};

const isMonthSelected = (selectedInterval: StayDate | null) => {
  if (
    !selectedInterval ||
    !selectedInterval.startDate ||
    !selectedInterval.endDate
  ) {
    return false;
  }

  return (
    selectedInterval.startDate.getDate() === 1 &&
    selectedInterval.endDate.getDate() ===
      endOfMonth(selectedInterval.endDate).getDate()
  );
};

export const useStayDates = (day: Date) => {
  const selectedStayDate = useSelectedStayDate();
  const getStayDates = useGetStayDates();

  const stayDates = [...getStayDates(day)];

  // A selected suggested stay date can be of another stay type not in the list.
  if (selectedStayDate && !isMonthSelected(selectedStayDate)) {
    stayDates.push(selectedStayDate);
  }

  const stayDatesOnToday = intervalsOnDay(stayDates, day, Day.Today);
  const intervalsOnDayBefore = intervalsOnDay(stayDates, day, Day.Yesterday);
  const intervalsOnDayAfter = intervalsOnDay(stayDates, day, Day.Tomorrow);

  const nearestIntervalFromDay = stayDatesOnToday.length
    ? stayDatesOnToday.sort((a, b) =>
        (a?.startDate ?? '') < (b?.startDate ?? '')
          ? Day.Tomorrow
          : Day.Yesterday
      )[0]
    : null;

  const stayDatesOnTodayWithSameEndDate = stayDatesOnToday.filter(
    (stayDate, index, self) =>
      self.findIndex(
        (sd) =>
          sd.endDate &&
          stayDate.endDate &&
          isSameDay(sd.endDate, stayDate.endDate)
      ) !== index
  );

  return {
    stayDatesOnToday,
    stayDateOnToday: getFirstOrNull(stayDatesOnToday),
    stayDateOnDayBefore: getFirstOrNull(intervalsOnDayBefore),
    stayDateOnDayAfter: getFirstOrNull(intervalsOnDayAfter),
    stayDatesOnTodayWithSameEndDate,
    isDayOnSelectedStayDate: isWithinSelectedInterval(day, selectedStayDate),
    isDayBeforeOnSelectedStayDate: isWithinSelectedInterval(
      addDays(day, Day.Yesterday),
      selectedStayDate
    ),
    isDayAfterOnySelectedStayDate: isWithinSelectedInterval(
      addDays(day, Day.Tomorrow),
      selectedStayDate
    ),
    stayDatesStartingOnDay: stayDatesOnToday.filter(
      (interval) => interval?.startDate && isSameDay(interval?.startDate, day)
    ),
    nearestStayDateFromToday: nearestIntervalFromDay,
    isFirstDayOfStayDate: isFirstDayOfInterval(nearestIntervalFromDay, day),
    isMonthSelected: isMonthSelected(selectedStayDate),
  };
};
