import { Box } from '@chakra-ui/react';
import {
  useTranslation,
  ThematicLink,
  useTrans,
  useGetThematicLink,
} from '@ae/shared';
import { InsideTextLink } from '@ae/shared-ui';

const HeaderTopBar = () => {
  const { t } = useTranslation('header');
  const Trans = useTrans();
  const { getLinkHref } = useGetThematicLink();

  const earlyBookingLink = getLinkHref(ThematicLink.EarlyBookingBitly);

  return (
    <Box
      background="ae.orange_100"
      padding="12px 1rem"
      fontSize={14}
      textAlign="center"
    >
      <Trans t={t} i18nKey="header.topBar.msg">
        text content
        <InsideTextLink target="_self" href={earlyBookingLink}>
          all stays top bar
        </InsideTextLink>
      </Trans>
    </Box>
  );
};

export default HeaderTopBar;
