import { useCallback } from 'react';
import { useGetAELink, AELink, useUserInitials } from '@ae/shared';

export const useLogout = () => {
  const { getLinkHref } = useGetAELink();
  const { resetUserInitials } = useUserInitials();

  const logout = useCallback(() => {
    resetUserInitials();
    window.location.href = getLinkHref(AELink.Logout);
  }, [getLinkHref, resetUserInitials]);

  return { logout };
};
