import { useTrackEvent } from '@ae/tagging';
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Center,
  Flex,
  forwardRef,
  Spinner,
} from '@chakra-ui/react';
import { MouseEvent } from 'react';

export type UIButtonProps = ChakraButtonProps & {
  icon?: React.ReactElement;
  label?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?:
    | 'primary'
    | 'link'
    | 'linkUnderlined'
    | 'secondary'
    | 'tertiary'
    | 'iconOutlined'
    | 'danger'
    | 'linkDanger'
    | 'linkDangerUnderlined';
  spinning?: boolean;
};

export const UIButton = forwardRef<UIButtonProps, 'button'>(
  (
    { icon, label, variant = 'primary', spinning = false, children, ...other },
    ref
  ) => {
    const { onClick, ...buttonProps } = other;
    const trackEvent = useTrackEvent();
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      trackEvent();
      if (onClick) {
        onClick(e);
      }
    };
    return (
      <ChakraButton
        ref={ref}
        onClick={handleClick}
        variant={variant}
        wordBreak="keep-all"
        isDisabled={buttonProps.disabled}
        {...buttonProps}
      >
        {spinning && (
          <Center position="absolute">
            <Spinner h="22px" />
          </Center>
        )}

        <Flex
          alignItems="center"
          h="100%"
          wordBreak="keep-all"
          style={{ visibility: spinning ? 'hidden' : 'visible' }}
        >
          {icon && <Center mr={2}>{icon}</Center>}
          {label}
          {children}
        </Flex>
      </ChakraButton>
    );
  }
);
