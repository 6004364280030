import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
);

export const checkboxTheme = {
  baseStyle: {
    control: {
      bg: 'white',
      border: '1px solid',
      borderColor: 'ae.green',
      borderRadius: '3px',
      _invalid: {
        borderColor: 'ae.red',
        border: '2px solid',
        color: 'ae.red',
      },
      _checked: {
        bg: 'ae.pink',
        borderColor: 'ae.green',
        _disabled: {
          bg: 'ae.pink',
          borderColor: 'ae.green',
          opacity: 0.5,
        },
      },
    },
  },
  variants: {
    without_label: definePartsStyle({
      label: defineStyle({
        marginLeft: 0,
      }),
    }),
  },
};
