import { useState, useEffect } from 'react';
import {
  AssetsProvider,
  CookieProvider,
  defaultRouterContextState,
  ExperimentProvider,
  getDevice,
  getQueryClient,
  I18nProvider,
  RouterProvider,
  TagProvider,
  useVersionLogging,
} from '@ae/shared';
import { Trans, useTranslation } from 'react-i18next';
import { axiosInstance } from '@ae/data-access';
import { Fonts, MediaProvider, sharedTheme } from '@ae/shared-ui';
import { ChakraProvider } from '@chakra-ui/react';
import { CSSReset } from '@ae/shared-comp';
import { QueryClientProvider } from 'react-query';
import { ApiProvider, LanguageProvider } from '@ae/i18n';
import { AuthProvider } from '@ae/auth';
import '../i18n';
import { standaloneComponentProps } from '../types';

/**
 * The `StandaloneComponent` is a wrapper component that provides various context providers
 * and configurations for the application. It sets up the environment for the child components
 * to function correctly, including internationalization, API access, authentication, and more.
 *
 * @param {string} appName - The name of the application.
 * @param {React.ReactNode} children - The child elements to render within the component.
 *
 * @returns {React.ReactElement} The rendered component with all the necessary providers.
 */
export function StandaloneComponent({
  appName,
  children,
}: {
  appName: string;
} & React.PropsWithChildren) {
  const [queryClient] = useState(() => getQueryClient());
  const { i18n } = useTranslation();
  const [pathname, setPathname] = useState(
    new URL(window.location.href).pathname
  );

  useVersionLogging(appName);

  // Trick to detect url change made on click from mr2 (see https://rajaosama.me/blogs/detect-react-route-change-in-vanilla-js).
  useEffect(() => {
    let url = window.location.href;

    const handleClick = () => {
      requestAnimationFrame(() => {
        if (url !== window.location.href) {
          setPathname(new URL(window.location.href).pathname);
        }
      });
    };

    document.body.addEventListener('click', handleClick, true);

    return () => {
      document.body.removeEventListener('click', handleClick, true);
    };
  }, []);

  const {
    keycloak,
    apiDomain,
    baseApi,
    assetsPath,
    supportedLngs,
    onLocaleChange,
    customer: { customerBasePath },
  } = standaloneComponentProps;

  // --- Context values ---

  const assetsContextValue = { assetsPath };

  const languageContextValue = {
    locale: i18n.language,
    supportedLngs,
    changeLocale: onLocaleChange,
  };

  const routerContextValue = {
    ...defaultRouterContextState,
    assetsPath,
    linkLegacyBehavior: true,
    pathname,
    domains: {
      customer: {
        basePath: customerBasePath ?? '',
      },
    },
  };

  return (
    <MediaProvider userDevice={getDevice(navigator.userAgent.toLowerCase())}>
      <CookieProvider value={document.cookie}>
        <ExperimentProvider>
          <I18nProvider useTranslation={useTranslation} Trans={Trans}>
            <ChakraProvider theme={sharedTheme} resetCSS={false}>
              <CSSReset />
              <QueryClientProvider client={queryClient}>
                <ApiProvider
                  apiDomain={apiDomain}
                  baseApi={baseApi}
                  axiosInstance={axiosInstance}
                >
                  <AssetsProvider value={assetsContextValue}>
                    <LanguageProvider value={languageContextValue}>
                      <RouterProvider value={routerContextValue}>
                        <AuthProvider keycloak={keycloak}>
                          <TagProvider>
                            <>
                              <Fonts />
                              {children}
                            </>
                          </TagProvider>
                        </AuthProvider>
                      </RouterProvider>
                    </LanguageProvider>
                  </AssetsProvider>
                </ApiProvider>
              </QueryClientProvider>
            </ChakraProvider>
          </I18nProvider>
        </ExperimentProvider>
      </CookieProvider>
    </MediaProvider>
  );
}
