import {
  forwardRef,
  RadioProps,
  Box,
  HStack,
  Icon,
  Radio,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useAssets } from '@ae/shared';

type Props = {
  label: string;
  label2?: string;
  icon?: React.ReactElement<typeof Icon>;
  disabled?: boolean;
  isChecked?: boolean;
  onChange?: (value: string) => void;
} & RadioProps;

export const UIRadioButton = forwardRef<Props, 'input'>(
  ({ label, label2, icon, disabled = false, ...otherRadioProps }, ref) => {
    const { cssUrl } = useAssets();
    return (
      <Radio
        ref={ref}
        position="relative"
        isDisabled={disabled}
        size="lg"
        _checked={{
          borderColor: 'ae.pink',
          backgroundColor: 'ae.pink',
          backgroundImage: cssUrl('/shared/images/icon/checkbox-radio-g.svg'),
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        {...otherRadioProps}
      >
        <HStack spacing={0}>
          {icon && (
            <Box mr="10px" display="inline-block">
              {icon}
            </Box>
          )}
          <VStack alignItems="flex-start" fontSize="md" spacing={0}>
            <Text>{label}</Text>
            {label2 && <Text>{label2}</Text>}
          </VStack>
        </HStack>
      </Radio>
    );
  }
);
