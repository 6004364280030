import { startOfWeek, startOfMonth, format, addDays } from 'date-fns';
import { fr, nl, de, enGB } from 'date-fns/locale';
import { useLanguage } from '@ae/i18n';
import { Week } from '../date';

const getLocaleFromString = (locale: string) => {
  if (locale.includes('fr')) return fr;
  if (locale.includes('nl')) return nl;
  if (locale.includes('de')) return de;
  return enGB;
};

export const useCalendar = () => {
  const language = useLanguage();
  const locale = getLocaleFromString(language);

  const getMonths = (
    startMonthIndex: number,
    year: number,
    numMonths: number = 12
  ) => {
    const monthDates = [...Array.from({ length: numMonths })].map((_, i) => {
      const monthIndex = (startMonthIndex + i) % numMonths;
      const month = new Date(year, monthIndex);
      return month;
    });
    return monthDates;
  };

  const getMonthName = (month: Date, withYear: boolean = false) => {
    return format(month, withYear ? 'LLLL yyyy' : 'LLLL', { locale });
  };

  /**
   * Get the week day names
   * @param numChars number of characters to return
   * @param upperCase if true, return the names in upper case
   * @returns the week day names
   * @example
   * const weekDayNames = getWeekDayNames(3, true);
   * ==> ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
   */
  const getWeekDayNames = (numChars: number = 3, upperCase: boolean = true) => {
    const weekStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    return [...Array.from({ length: 7 })].map((_, i) => {
      const formatedDate = format(addDays(weekStartDate, i), 'EEE', {
        locale,
      }).slice(0, numChars);
      return upperCase ? formatedDate.toUpperCase() : formatedDate;
    });
  };

  /**
   * return an array of 6 weeks (a week is an array of 7 days)
   */
  const getMonthlyDates = (month: Date): Week[] => {
    const startOfTheSelectedMonth = startOfMonth(month);
    const startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });

    const getDatesOfWeek = (date: Date): Week => {
      return [...Array.from({ length: 7 })].map((_, i) =>
        addDays(date, i)
      ) as Week;
    };

    return [...Array.from({ length: 6 })].map((_, i) =>
      getDatesOfWeek(addDays(startDate, 7 * i))
    );
  };

  return {
    getMonths,
    getMonthName,
    getWeekDayNames,
    getMonthlyDates,
  };
};
