import { createContext, useContext } from 'react';

interface AssetsContextState {
  assetsPath: string;
}

export const AssetsContext = createContext<AssetsContextState>({
  assetsPath: '',
});

export const useAssets = () => {
  const { assetsPath } = useContext(AssetsContext);

  const cssUrl = (url: string) => `url('${assetsPath}${url}')`;

  return {
    cssUrl,
    assetsPath,
  };
};

export const AssetsProvider = ({
  value,
  children,
}: {
  value: AssetsContextState;
  children: React.ReactElement;
}) => {
  return (
    <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>
  );
};
