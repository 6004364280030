import { Box, BoxProps } from '@chakra-ui/react';
import { StarIcon } from '@ae/icons';

export type UIRatingWithStarsProps = {
  nbrStar: number;
  size?: 'sm' | 'md' | 'lg';
  ariaLabel?: string;
};

export const UIRatingWithStars = ({
  nbrStar = 0,
  size = 'md',
  ariaLabel = '',
  ...boxProps
}: UIRatingWithStarsProps & BoxProps) => {
  const starSize = {
    sm: '15px',
    md: '20px',
    lg: '25px',
  };
  const displaySemistar = nbrStar % 1 > 0;

  return (
    <Box
      color="ae.green"
      {...boxProps}
      whiteSpace="nowrap"
      aria-label={ariaLabel}
    >
      {Array.from({ length: Math.floor(nbrStar) }).map((_, i) => (
        <StarIcon key={i} size={starSize[size]} />
      ))}
      {displaySemistar && (
        <Box display="inline-block" position="relative" top="-2px">
          +
        </Box>
      )}
    </Box>
  );
};
