import { ChevronRightIcon, DotsIcon } from '@ae/icons';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Popover as BasePopover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverTrigger,
  BoxProps,
  forwardRef,
  DrawerCloseButton,
  DrawerHeader,
  Text,
  PopoverCloseButton,
  useDisclosure,
  Flex,
  FlexProps,
  PlacementWithLogical,
  VStack,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useViewport } from '../../providers';

const DotsButton = forwardRef<
  BoxProps & { label: string; icon?: React.ReactElement },
  'div'
>(({ label, icon, ...props }, ref) => (
  <Box
    ref={ref}
    padding="5px 0px 5px 10px"
    display="flex"
    justifyContent="right"
    as="button"
    aria-label={label}
    {...props}
  >
    {icon ? icon : <DotsIcon />}
  </Box>
));

type MenuItemProps = {
  label1?: string;
  label2?: string;
  labelColor?: string;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  hideRightIcon?: boolean;
  children?: React.ReactNode;
};

const MenuItem = ({
  label1,
  label2,
  labelColor = 'ae.green',
  leftIcon,
  rightIcon,
  hideRightIcon = false,
  children,
  ...props
}: MenuItemProps & FlexProps) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    cursor="pointer"
    role="menuitem"
    {...props}
  >
    <Flex flexGrow={2}>
      {leftIcon && <Box mr="15px">{leftIcon}</Box>}
      <Flex flexDir="column" flexGrow={2} alignItems="flex-start">
        {label1 && (
          <Text
            fontSize="16px"
            fontWeight={label2 ? 'bold' : 'normal'}
            color={labelColor}
          >
            {label1}
          </Text>
        )}
        {label2 && (
          <Text mt="5px" fontSize="16px" color={labelColor}>
            {label2}
          </Text>
        )}
        {children}
      </Flex>
    </Flex>

    {!hideRightIcon && (
      <Box ml="25px">
        {rightIcon ? (
          rightIcon
        ) : (
          <ChevronRightIcon color="ae.green" size="11px" />
        )}
      </Box>
    )}
  </Flex>
);

const Menu = ({ children }: { children: ReactNode }) => {
  return (
    <VStack
      mx="5px"
      spacing="15px"
      alignItems="stretch"
      divider={<Box height="1px" width="100%" color="#F1F1F1" />}
    >
      {children}
    </VStack>
  );
};

type PopoverProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  title: string;
  buttonLabel: string;
  buttonIcon?: React.ReactElement;
  children?: React.ReactNode;
  defaultIsOpen?: boolean;
  placement?: PlacementWithLogical;
  width?: string;
  dataTestId?: string;
  triggerComponent?: React.ReactNode;
};

export const UIPopover = ({
  isOpen: isBaseOpen,
  onOpen: onBaseOpen,
  onClose: onBaseClose,
  title,
  buttonLabel,
  buttonIcon,
  children,
  defaultIsOpen = false,
  placement = 'bottom-end',
  width = 'auto',
  dataTestId,
  triggerComponent,
}: PopoverProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen });
  const { isMobile } = useViewport();

  if (isMobile) {
    return (
      <>
        {triggerComponent ? (
          triggerComponent
        ) : (
          <DotsButton
            label={buttonLabel}
            icon={buttonIcon}
            onClick={onBaseOpen ?? onOpen}
          />
        )}

        <Drawer
          placement="bottom"
          isOpen={isBaseOpen ?? isOpen}
          onClose={onBaseClose ?? onClose}
        >
          <DrawerOverlay />
          <DrawerContent
            pb="20px"
            boxShadow={{ base: 'base', md: 'none' }}
            borderRadius={{ base: '20px 20px 0px 0px', md: '0' }}
            borderStyle={{ lg: 'solid' }}
            borderColor={{ lg: 'ae.greyF1' }}
            data-testid={dataTestId}
          >
            <DrawerCloseButton
              border={0}
              fontSize="12px"
              p="25px"
              top="10px"
              color="ae.green"
              outline="none"
              _hover={{ outline: 'none' }}
              _active={{ outline: 'none' }}
              _focus={{ outline: 'none' }}
            />
            <DrawerHeader maxW="calc( 100% - 40px)">
              <Text
                fontSize="22px"
                as="span"
                color="ae.green"
                fontFamily="heading"
              >
                {title}
              </Text>
            </DrawerHeader>
            <DrawerBody>{children}</DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <BasePopover
      isOpen={isBaseOpen ?? isOpen}
      onClose={onBaseClose ?? onClose}
      placement={placement}
    >
      <PopoverTrigger>
        <DotsButton
          label={buttonLabel}
          icon={buttonIcon}
          onClick={onBaseOpen ?? onOpen}
        />
      </PopoverTrigger>
      <PopoverContent
        width={width}
        background="#fff"
        borderStyle={{ base: 'none' }}
        borderRadius={{ base: '20px 20px 0 0', md: '20px' }}
        _active={{ border: 0 }}
        data-testid={dataTestId}
      >
        <Box
          boxShadow={{ base: 'base' }}
          borderRadius={{ base: '20px 20px 0 0', md: '20px' }}
          p="12px 10px 20px 10px"
        >
          <PopoverCloseButton
            border={0}
            fontSize="12px"
            p="25px"
            top="12px"
            color="ae.green"
            outline="none"
            _hover={{ outline: 'none' }}
            _active={{ outline: 'none' }}
            _focus={{ outline: 'none' }}
          />
          <PopoverHeader borderBottomStyle="none" maxW="calc(100% - 40px)">
            <Text
              as="span"
              color="ae.green"
              fontSize="22px"
              fontFamily="heading"
            >
              {title}
            </Text>
          </PopoverHeader>
          <PopoverBody>{children}</PopoverBody>
        </Box>
      </PopoverContent>
    </BasePopover>
  );
};

UIPopover.Menu = Menu;
UIPopover.MenuItem = MenuItem;
