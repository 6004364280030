import {
  Center,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { UIRadioButton } from '@ae/shared-ui';
import { formatToBrusselsTz, useTranslation } from '@ae/shared';
import { Locale, useLanguage } from '@ae/i18n';
import { isSameDay } from 'date-fns';
import { StayDate } from '@ae/shared-comp';
import { useSelectedStayDate } from './stores';
import { useSuggestStayDatesWithMoreAvailability } from './hooks';

const sortOptions = (a: Option, b: Option) => {
  if (a.showMoreAvailabilitiesLabel !== b.showMoreAvailabilitiesLabel) {
    return a.showMoreAvailabilitiesLabel ? 1 : -1;
  }

  const dateAStart = a.stayDate.startDate
    ? new Date(a.stayDate.startDate).getTime()
    : 0;
  const dateBStart = b.stayDate.startDate
    ? new Date(b.stayDate.startDate).getTime()
    : 0;
  const dateAEnd = a.stayDate.endDate
    ? new Date(a.stayDate.endDate).getTime()
    : 0;
  const dateBEnd = b.stayDate.endDate
    ? new Date(b.stayDate.endDate).getTime()
    : 0;

  if (dateAStart !== dateBStart) {
    return dateAStart - dateBStart;
  }
  return dateAEnd - dateBEnd;
};

type Option = {
  stayDate: StayDate;
  showMoreAvailabilitiesLabel: boolean;
};

export type CalendarDayStayDatesSelectionPopoverProps = {
  formattedDate: string;
  day: Date;
  onClick: (stayDate: StayDate) => void;
  focusCalendarPopover: () => void;
  stayDatesStartingOnDay: StayDate[];
  stayDatesOnTodayWithSameEndDate: StayDate[];
};

export const CalendarDayStayDatesPopover = ({
  formattedDate,
  day,
  onClick,
  focusCalendarPopover,
  children,
  stayDatesStartingOnDay,
  stayDatesOnTodayWithSameEndDate,
}: CalendarDayStayDatesSelectionPopoverProps & React.PropsWithChildren) => {
  const language = useLanguage();
  const { t } = useTranslation('mr');
  const { isOpen, onToggle, onClose } = useDisclosure({
    onClose: focusCalendarPopover,
  });
  const selectedStayDate = useSelectedStayDate();
  const stayDatecustomSuggestions =
    useSuggestStayDatesWithMoreAvailability(day);

  stayDatesOnTodayWithSameEndDate = stayDatesOnTodayWithSameEndDate.filter(
    (s) => s.stayType === 'wk' || s.stayType === 'lw' || s.stayType === 'mw'
  );

  const stayDatesOptions = [
    ...stayDatesStartingOnDay.map((stayDate) => ({
      stayDate,
      showMoreAvailabilitiesLabel: false,
    })),
    ...[...stayDatecustomSuggestions, ...stayDatesOnTodayWithSameEndDate].map(
      (stayDate) => ({
        stayDate,
        showMoreAvailabilitiesLabel: true,
      })
    ),
  ]
    .reduce((acc: Option[], current) => {
      if (!acc.some((item) => item.stayDate.id === current.stayDate.id)) {
        acc.push(current);
      }
      return acc;
    }, [])
    .sort(sortOptions);

  if (stayDatesOptions.length <= 1) {
    return children;
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Center
          cursor="pointer"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onToggle();
          }}
        >
          {children}
        </Center>
      </PopoverTrigger>
      <PopoverContent
        borderColor="ae.warning"
        data-testid={`calendar-day-popover-${formattedDate}`}
      >
        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverBody px="20px" py="30px">
          <VStack alignItems="flex-start">
            <Text>{t('mr1.choose_a_stay')}</Text>

            {stayDatesOptions.map(
              ({ stayDate, showMoreAvailabilitiesLabel }) => (
                <UIRadioButton
                  key={stayDate.id}
                  label={`${t('mr1.stay_aria', {
                    start: stayDate?.startDate
                      ? formatToBrusselsTz(
                          stayDate?.startDate,
                          'eee dd MMM',
                          language as Locale
                        )
                      : '',
                    end: stayDate?.endDate
                      ? formatToBrusselsTz(
                          stayDate?.endDate,
                          'eee dd MMM',
                          language as Locale
                        )
                      : '',
                  })}`}
                  label2={
                    showMoreAvailabilitiesLabel
                      ? `${t('mr1.date.more_avaibilities')}`
                      : ''
                  }
                  value=""
                  isChecked={
                    selectedStayDate?.startDate &&
                    selectedStayDate?.endDate &&
                    stayDate?.startDate &&
                    stayDate?.endDate
                      ? isSameDay(
                          selectedStayDate?.startDate,
                          stayDate.startDate
                        ) &&
                        isSameDay(selectedStayDate?.endDate, stayDate.endDate)
                      : false
                  }
                  variant="round"
                  onChange={() => {
                    onClick(stayDate);
                    onClose();
                  }}
                />
              )
            )}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
