import { useContext } from 'react';
import { LanguageContext } from '../providers/LanguageProvider';

export const getLanguagePartFromLocale = (locale: string) => {
  if (!locale || locale.indexOf('-') < 0) return locale;

  const p = locale.split('-');
  return p[0];
};

export const useLanguage = () => {
  const { locale } = useContext(LanguageContext);

  return getLanguagePartFromLocale(locale);
};
