import { GlobalTagContext } from './TagProvider';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import TagManager from 'react-gtm-module';
import { DataLayers, TagElementContextState } from './types';

export const TagElementContext = createContext<TagElementContextState>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  trackEvent: () => {},
});

export const useTrackEvent = () => {
  const { trackEvent } = useContext(TagElementContext);
  return trackEvent;
};

export const dataLayerPush = (data: object) => {
  TagManager.dataLayer({
    dataLayer: data,
  });
};

export const useTag = () => {
  const data = useContext(GlobalTagContext);

  const trackEvent = useCallback(
    (dataLayer?: DataLayers) => {
      if (dataLayer?.ua) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'GAEvent',
            ...dataLayer?.ua,
          },
        });
      }
      if (dataLayer?.ga4) {
        TagManager.dataLayer({
          dataLayer: {
            ...data,
            ...dataLayer?.ga4,
          },
        });
      }
    },
    [data]
  );

  return trackEvent;
};

export const useTagOnce = (dataLayer?: DataLayers) => {
  const tracked = useRef(false);
  const trackEvent = useTag();

  useEffect(() => {
    if (!tracked.current) {
      trackEvent(dataLayer);
      tracked.current = true;
    }
  }, [dataLayer, trackEvent]);
};
