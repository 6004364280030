import { Text } from '@chakra-ui/react';

export const UIFormLabel = ({
  label,
  isRequired = false,
  mb = '5px',
  fontSize = '14px',
}: {
  label: string;
  isRequired?: boolean;
  mb?: string;
  fontSize?: string;
}) => (
  <Text fontWeight={600} mb={mb} fontSize={fontSize}>
    {label}
    {isRequired ? ' *' : ''}
  </Text>
);
