import { Alert as BaseAlert, Text } from '@chakra-ui/react';
import { UIAlertIconDisplay } from '@ae/shared-ui';
import { AlertIcon, InfoIconSolid } from '@ae/icons';

type Props = {
  description: string;
  status: 'warning' | 'success' | 'error' | 'info';
  displayIcon: boolean;
};

export const UIAlertMessage = ({ description, status, displayIcon }: Props) => {
  let icon;
  const colorMessage = status === 'info' ? '' : 'ae.grey_400';

  if (displayIcon && status === 'info') {
    icon = <InfoIconSolid />;
  } else if (displayIcon && status !== 'info') {
    icon = (
      <UIAlertIconDisplay>
        <AlertIcon />
      </UIAlertIconDisplay>
    );
  }

  return (
    <BaseAlert
      mt="8px"
      p="10px"
      status={status}
      alignItems={{
        base: 'center',
      }}
      gap="1"
    >
      {icon}
      <Text color={colorMessage} px="8px">
        {description}
      </Text>
    </BaseAlert>
  );
};
