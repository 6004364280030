import { endOfYear, isAfter, isWithinInterval, startOfYear } from 'date-fns';
import { StayDate } from '@ae/shared-comp';
import { useGetStayDates, useSelectedStayType } from '../stores';

export const useSuggestStayDatesWithMoreAvailability = (
  day: Date
): StayDate[] => {
  const selectedStayType = useSelectedStayType();
  const getStayDates = useGetStayDates();

  const lastOfYearWeekStaySate = () => {
    if (
      selectedStayType !== 'wk' &&
      selectedStayType !== 'lw' &&
      selectedStayType !== 'mw'
    ) {
      return [];
    }

    const startOfYearDate = startOfYear(day);
    const endOfYearDate = endOfYear(day);
    const christmasDate = new Date(day.getFullYear(), 11, 25); // December 25th

    if (!isAfter(day, christmasDate)) {
      return [];
    }

    const foundStayDates = getStayDates(day, '1w').filter((stayDate) =>
      isWithinInterval(stayDate.startDate ?? new Date(), {
        start: startOfYearDate,
        end: endOfYearDate,
      })
    );

    foundStayDates.sort(
      (a, b) =>
        (b.startDate ?? new Date()).getTime() -
        (a.startDate ?? new Date()).getTime()
    );

    return foundStayDates.length > 0 ? [foundStayDates[0]] : [];
  };

  const christmasWeekStayDate = () => {
    if (
      selectedStayType !== 'wk' &&
      selectedStayType !== 'lw' &&
      selectedStayType !== 'mw'
    ) {
      return [];
    }

    const christmasDate = new Date(day.getFullYear(), 11, 25); // December 25th

    const christmasWeekStayDate = getStayDates(day, '1w').find(
      (stayDate) =>
        stayDate.startDate &&
        stayDate.endDate &&
        isWithinInterval(christmasDate, {
          start: stayDate.startDate,
          end: stayDate.endDate,
        })
    );

    if (!christmasWeekStayDate || !christmasWeekStayDate?.startDate) {
      return [];
    }

    return isWithinInterval(day, {
      start: christmasWeekStayDate?.startDate,
      end: christmasDate,
    })
      ? [christmasWeekStayDate]
      : [];
  };

  return [...christmasWeekStayDate(), ...lastOfYearWeekStaySate()];
};
