import {
  Checkbox as BaseCheckbox,
  CheckboxProps,
  forwardRef,
  Text,
} from '@chakra-ui/react';

type Props = {
  label?: string | React.ReactNode;
  value?: string;
  error?: string;
};

export const UICheckbox = forwardRef<Props & CheckboxProps, 'input'>(
  ({ label, value, error, defaultChecked, ...others }, ref) => (
    <BaseCheckbox
      ref={ref}
      colorScheme="checkBox"
      iconColor="ae.green"
      size={others.size || 'lg'}
      isInvalid={!!error}
      defaultChecked={defaultChecked}
      value={value}
      {...others}
    >
      {label && (
        <Text
          fontSize={others.fontSize ?? '14px'}
          color={others.color ?? 'ae.black'}
          whiteSpace="pre-line"
        >
          {label}
        </Text>
      )}
      {error && (
        <Text color="ae.red" fontSize="14px">
          {error}
        </Text>
      )}
    </BaseCheckbox>
  )
);
