import { useAssets, useTranslation } from '@ae/shared';
import { useLanguage } from '@ae/i18n';
import { useCallback } from 'react';
import { LinkGetter, useLink } from '../hooks';

export enum ExternalLink {
  TrustpilotRating,
  Becommerce,
  ECommerceEurope,
}

export const useGetExternalLinks = () => {
  const lng = useLanguage();
  const { t } = useTranslation(['home', 'footer']);
  const { assetsPath } = useAssets();

  const getLink = useCallback(
    (link: ExternalLink): LinkGetter => {
      switch (link) {
        case ExternalLink.TrustpilotRating:
          return {
            href: () => {
              switch (lng) {
                case 'nl':
                  return 'https://nl-be.trustpilot.com/evaluate/ardennes-etape.nl';
                case 'fr':
                  return 'https://fr-be.trustpilot.com/evaluate/ardennes-etape.be';
                case 'en':
                  return 'https://www.trustpilot.com/evaluate/ardennes-etape.co.uk';
                case 'de':
                  return 'https://de.trustpilot.com/evaluate/ardennes-etape.nl';
                default:
                  return '';
              }
            },
            title: t('home.ereputation.alt_trustpilot_website'),
            alt: t('home.ereputation.alt_trustpilot_website'),
            target: '_blank',
            datatestid: 'trustpilot-rating-link',
          };
        case ExternalLink.Becommerce:
          return {
            href: () => {
              switch (lng) {
                case 'nl':
                  return 'https://www.becommerce.be/nl_BE/report/html/becommerce_certification.report_certificate/16338';
                case 'fr':
                  return 'https://www.becommerce.be/fr_BE/report/html/becommerce_certification.report_certificate/16338';
                case 'en':
                  return 'https://www.becommerce.be/en_US/report/html/becommerce_certification.report_certificate/16338';
                case 'de':
                  return 'https://www.becommerce.be/en_US/report/html/becommerce_certification.report_certificate/16338';
                default:
                  return '';
              }
            },
            title: t('footer:footer.alt_becommerce'),
            alt: t('footer:footer.alt_becommerce'),
            target: '_blank',
            image: `${assetsPath}/shared/images/be-commerce.png`,
            datatestid: 'becommerce-link-footer',
          };
        case ExternalLink.ECommerceEurope:
          return {
            href: 'https://ecommerce-europe.eu/',
            title: t('footer:footer.alt_ecommerce_europe'),
            alt: t('footer:footer.alt_ecommerce_europe'),
            target: '_blank',
            image: `${assetsPath}/shared/images/ecommerce-europe.png`,
          };
        default:
          throw Error('link not found');
      }
    },
    [assetsPath, lng, t]
  );

  return useLink<ExternalLink>(getLink);
};
