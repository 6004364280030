import { useSearchUrl } from '@ae/shared-comp';
import { useState } from 'react';
import { Locale } from '@ae/i18n';

export type translations = { [x in Locale]: string };

export const useSearchUrlTranslation = () => {
  const { parseSearchUrl, generateSearchUrl } = useSearchUrl();
  const [translations] = useState<translations>(() => {
    const searchValues = parseSearchUrl();

    return {
      [Locale.Fr]: generateSearchUrl(searchValues, Locale.Fr),
      [Locale.Nl]: generateSearchUrl(searchValues, Locale.Nl),
      [Locale.En]: generateSearchUrl(searchValues, Locale.En),
      [Locale.De]: generateSearchUrl(searchValues, Locale.De),
    };
  });

  const getSearchUrl = (locale: Locale) => translations && translations[locale];

  return { getSearchUrl };
};
