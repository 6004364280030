import { buttonTheme } from './buttonTheme';

export const radioTheme = {
  baseStyle: {
    control: {
      borderRadius: '5px',
      borderWidth: '1px',
      borderColor: 'ae.green',
    },
  },
  variants: {
    round: { control: { borderRadius: '50%' } },

    adminFilter: {
      container: {
        ...buttonTheme.sizes.sm,
        ...buttonTheme.baseStyle,
        ...buttonTheme.variants.tertiary,
        width: 'auto',
        justifyContent: 'center',
        _checked: {
          ...buttonTheme.variants.secondary,
          border: '1px solid', // we keep the border to avoid the button to move when checked
        },
      },
      label: {
        marginStart: 0,
      },
      control: {
        display: 'none',
      },
    },
  },
};
