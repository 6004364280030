import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    ':first-of-type': {
      // TO avoid SSR issue
      border: 'none',
    },
  },
  panel: {
    marginLeft: 3,
  },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
