export const headingTheme = {
  defaultProps: {
    size: 'xl',
    variant: 'custom',
    colorScheme: 'brand',
  },
  sizes: {
    '2xl': {
      fontSize: '50px', // H1
      fontWeight: 400,
    },
    xl: {
      fontSize: '30px', // H2
      fontWeight: 400,
    },
    lg: {
      fontSize: '20px', // H3
      fontWeight: 400,
    },
    md: {
      fontSize: '16px', // H4
      fontWeight: 400,
    },
  },
};
