import { CustomerJsonldReadCustomer } from '@ae/data-access';
import { useEffect, useCallback } from 'react';
import { usePersistedState } from './usePersistedState';
import { useMe } from './useMe';

const getUserInitials = (user: CustomerJsonldReadCustomer | undefined) => {
  if (user) {
    return `${user.firstname?.substring(0, 1)}${user.name?.substring(
      0,
      1
    )}`.toUpperCase();
  }
  return null;
};

export const useUserInitials = () => {
  const { data: user, isLoading } = useMe({ retry: 1 });
  const [userInitials, setUserInitials] =
    usePersistedState<string>('user_initials');

  useEffect(() => {
    if (!isLoading) {
      if (user) {
        setUserInitials(getUserInitials(user));
      } else {
        setUserInitials(null);
      }
    }
  }, [user, isLoading, setUserInitials]);

  const resetUserInitials = useCallback(() => {
    setUserInitials(null);
  }, [setUserInitials]);

  return {
    userInitials,
    userIsLoading: isLoading,
    resetUserInitials,
  };
};
