import { Link, MenuItem } from '@chakra-ui/react';
import { useLogout } from '@ae/auth';
import { useMe, useTranslation } from '@ae/shared';
import { useTag } from '@ae/tagging';

type Props = {
  withMenu?: boolean;
};

export const LogoutLink = ({ withMenu = false }: Props) => {
  const { t } = useTranslation('header');
  const { logout } = useLogout();
  const { data } = useMe();
  const trackEvent = useTag();

  const onClick = () => {
    trackEvent({
      ga4: {
        event: 'account_logout',
      },
    });
    logout();
  };
  if (!data) {
    return null;
  }

  const link = (
    <Link
      display="block"
      width="100%"
      fontSize="16px"
      color="ae.green"
      onClick={onClick}
      data-testid="header-logout"
      role="link"
      p="8px"
    >
      {t('header.logout')}
    </Link>
  );

  if (withMenu) {
    return (
      <MenuItem cursor="default" _hover={{ bgColor: 'white' }}>
        {link}
      </MenuItem>
    );
  }
  return link;
};
