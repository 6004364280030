import { Image, ImageProps } from '@chakra-ui/react';
import { Link } from '@ae/shared-ui';

type Props = {
  href: string;
};

export const LinkImage = ({ href, ...rest }: Props & ImageProps) => (
  <Link href={href}>
    <Image {...rest} />
  </Link>
);
