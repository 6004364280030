import { useLogin } from '@ae/auth';
import { useMe } from '@ae/shared';
import {
  AddRentalToFavoriteListModal,
  RentalFavoritesTrackingContextState,
  RentalFavoritesTrackingProvider,
} from '@ae/shared-comp';
import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { RentalFavoritesNotifier } from './RentalFavoritesNotifier';

export const RentalFavoritesAuthentication = () => {
  const { authenticated } = useMe();
  const { login } = useLogin();
  const [selectedRentalId, setSelectedRentalId] = useState('');
  const {
    isOpen: isAddRentalToFavoriteListModalOpen,
    onOpen: onAddRentalToFavoriteListModalOpen,
    onClose: onAddRentalToFavoriteListModalClose,
  } = useDisclosure();
  const [rentalFavoriteTracking, setRentalFavoriteTracking] =
    useState<RentalFavoritesTrackingContextState>({
      listIndex: 0,
      listName: '',
      listId: '',
    });
  const onOpenRentalFavoritesModalEvent = useCallback(
    (
      e: CustomEvent<{
        rentalId: string;
        listIndex: number;
        listName: string;
        listId: string;
      }>
    ) => {
      if (!authenticated) {
        login();
        return;
      }
      setRentalFavoriteTracking({
        listIndex: Number(e.detail.listIndex),
        listName: e.detail.listName,
        listId: e.detail.listId,
      });
      setSelectedRentalId(e.detail.rentalId);
      onAddRentalToFavoriteListModalOpen();
    },
    [authenticated, login, onAddRentalToFavoriteListModalOpen]
  );

  useEffect(() => {
    document.addEventListener(
      'openRentalFavoritesModal',
      onOpenRentalFavoritesModalEvent as EventListener
    );
    return () =>
      document.removeEventListener(
        'openRentalFavoritesModal',
        onOpenRentalFavoritesModalEvent as EventListener
      );
  }, [
    selectedRentalId,
    onAddRentalToFavoriteListModalOpen,
    onOpenRentalFavoritesModalEvent,
  ]);

  return (
    <RentalFavoritesTrackingProvider value={rentalFavoriteTracking}>
      <RentalFavoritesNotifier selectedRentalId={selectedRentalId}>
        <AddRentalToFavoriteListModal
          isOpen={isAddRentalToFavoriteListModalOpen}
          onClose={onAddRentalToFavoriteListModalClose}
          rentalId={selectedRentalId}
        />
      </RentalFavoritesNotifier>
    </RentalFavoritesTrackingProvider>
  );
};
