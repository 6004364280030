import { useCallback } from 'react';
import { locales } from '@ae/i18n';
import { useCookie } from '@ae/shared';

export const useCrossDomainCookie = () => {
  const { getCookie, setCookie } = useCookie();

  const setCookieAndCleanOtherCookies = useCallback(
    (
      cookieName: string,
      cookieValue: string | null,
      subdomain: string,
      expires?: Date | null
    ) => {
      // Remove cookies that could override the right one.
      [...locales, 'www'].map((value) => {
        setCookie(cookieName, '', {
          path: '/',
          httpOnly: false,
          expires: new Date(0), // Expire now.
          domain:
            value === 'www' ? `${value}${subdomain}` : `.${value}${subdomain}`,
        });
      });

      setCookie(cookieName, cookieValue, {
        path: '/',
        httpOnly: false,
        expires: expires ? expires : undefined,
        domain: subdomain,
      });
    },
    [setCookie]
  );

  return {
    getCookie,
    setCookie: setCookieAndCleanOtherCookies,
  };
};
