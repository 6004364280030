import { useTrackEvent } from '@ae/tagging';
import {
  forwardRef,
  IconButton as BaseIconButton,
  IconButtonProps as BaseIconButtonProps,
  Spinner,
} from '@chakra-ui/react';
import { MouseEvent } from 'react';

type CustomIconButtonProps = {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'iconOutlined';
  spinning?: boolean;
};

export type IconButtonProps = CustomIconButtonProps & BaseIconButtonProps;

export const UIRoundIcon = forwardRef<IconButtonProps, 'button'>(
  (
    {
      variant = 'iconOutlined',
      size = 'md',
      spinning = false,
      fontSize,
      h,
      w,
      ...other
    },
    ref
  ) => {
    const { onClick, ...otherProps } = other;
    const trackEvent = useTrackEvent();
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      trackEvent();
      if (onClick) {
        onClick(e);
      }
    };

    // default size if not provided
    const defaultSize: any = {
      sm: { iconSize: '32px', fontSize: '14px' },
      md: { iconSize: '44px', fontSize: '18px' },
      lg: { iconSize: '50px', fontSize: '20px' },
    };

    return (
      <BaseIconButton
        isRound
        ref={ref}
        onClick={handleClick}
        variant={variant}
        minW={w || defaultSize[size].iconSize}
        h={h || defaultSize[size].iconSize}
        w={w || defaultSize[size].iconSize}
        fontSize={fontSize || defaultSize[size].fontSize}
        {...otherProps}
      >
        {spinning && <Spinner />}
      </BaseIconButton>
    );
  }
);
