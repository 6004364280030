import { createContext, useContext } from 'react';

export interface KeycloakProps {
  id?: string;
  realm?: string;
  url?: string;
}

export interface KeycloakConfig {
  id: string;
  realm: string;
  url: string;
}

interface AuthContextState {
  keycloak: KeycloakConfig;
}

export const AuthContext = createContext<AuthContextState>({
  keycloak: {
    id: '',
    realm: '',
    url: '',
  },
});

export const useAuth = () => {
  const values = useContext(AuthContext);
  return values;
};

export const AuthProvider = ({
  keycloak,
  children,
}: {
  keycloak?: KeycloakProps | undefined;
  children: React.ReactElement;
}) => {
  return (
    <AuthContext.Provider
      value={{
        keycloak: {
          id: keycloak?.id ?? process.env['NX_KEYCLOAK_ID'] ?? '',
          realm: keycloak?.realm ?? process.env['NX_KEYCLOAK_REALM'] ?? '',
          url: keycloak?.url ?? process.env['NX_KEYCLOAK_URL'] ?? '',
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
