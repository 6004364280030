import { Box, BoxProps } from '@chakra-ui/react';
import { useViewport } from '../../providers';

export type SimpleCardProps = {
  disabledOnDesktop?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
};

export const UISimpleCard = ({
  disabledOnDesktop = false,
  noPadding = false,
  children,
  ...props
}: SimpleCardProps & BoxProps) => {
  const { isDesktop } = useViewport();

  if (disabledOnDesktop && isDesktop) {
    return <Box {...props}>{children}</Box>;
  }

  return (
    <Box
      boxShadow="0px 2px 3px rgba(0, 0, 0, 0.119509), 0px 8px 14px rgba(74, 74, 87, 0.08)"
      borderRadius="20px"
      backgroundColor="white"
      p={noPadding ? 0 : '20px'}
      w="100%"
      overflow={'hidden'}
      {...props}
    >
      {children}
    </Box>
  );
};
