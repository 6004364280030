import { useLanguage } from '@ae/i18n';
import { useCallback } from 'react';

import { useApiBookingsGetCollection } from '@ae/data-access';
import { LinkGetter, useGetDomain, useLink, useMe } from '../hooks';
import { useDate, useTranslation } from '@ae/shared';

export enum ThematicLink {
  New,
  HotTub,
  Redu,
  Best,
  Chalets,
  Dog,
  PoolTable,
  Nature,
  Swimmingpool,
  Sauna,
  SaunaView,
  Hotel,
  Fireplace,
  Stays2026,
  UnusualAccommodations,
  Castles,
  ByTheWater,
  Autumn,
  DeerRutting,
  View,
  NewYear,
  Jacuzzi,
  SaintsDay,
  Romantic,
  VoucherOrder,
  HiddenGem,
  EarlyBooking,
  EarlyBookingBitly,
  Winter,
  Wellness,
}

export const useGetThematicLink = () => {
  const lng = useLanguage();
  const { dayjs } = useDate();
  const { t } = useTranslation('thematic');
  const { prodDomain, apiDomain, getBeProdDomain } = useGetDomain();
  const { authenticated } = useMe();
  const { data: bookings } = useApiBookingsGetCollection({
    query: { enabled: authenticated },
  });

  const getLink = useCallback(
    (link: ThematicLink): LinkGetter => {
      switch (link) {
        case ThematicLink.New:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/nouveautes`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/nieuw`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/neu`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/new`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.new.label'),
            title: t('thematic.popular.new.label'),
          };

        case ThematicLink.Jacuzzi:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Vacances-Jacuzzi`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Vakantie-Jacuzzi`;
                case 'de':
                  return `${prodDomain}/holiday-houses-ardennes/Holidays-Jacuzzi`;
                case 'en':
                  return `${prodDomain}/ferienhauser-ardennen/Ferien-Jacuzzi`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.jacuzyThematic.label'),
            title: t('thematic.popular.jacuzyThematic.label'),
          };

        case ThematicLink.View:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/terrasse-vue-imprenable`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/terras-met-uniek-uitzicht`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/terrasse-blick`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/terrace-breathtaking-view`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.view.label'),
            title: t('thematic.popular.view.label'),
          };

        case ThematicLink.NewYear:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Nouvel-An`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Nieuwjaar`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Neujahr`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/New-Year`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.newYear.label'),
            title: t('thematic.popular.newYear.label'),
          };

        case ThematicLink.DeerRutting:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/ecouter-brame-du-cerf`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/luisteren-naar-burlende-herten`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/hirschrohren`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/listening-to-the-belling-of-deer`;
                default:
                  return '';
              }
            },

            alt: t('thematic.popular.deerRutting.label'),
            title: t('thematic.popular.deerRutting.label'),
          };

        case ThematicLink.Autumn:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/automne`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/herfst`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/herbst`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/autumn`;
                default:
                  return '';
              }
            },
          };
        case ThematicLink.SaintsDay:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-99p/Week-end/2024-10-31_to_2024-11-03/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-99p/Weekend/2024-10-31_to_2024-11-03?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/herbst`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/autumn`;
                default:
                  return '';
              }
            },
          };

        case ThematicLink.HotTub:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Vacances-Jacuzzi`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Vakantie-Jacuzzi`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Ferien-Jacuzzi`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Holidays-Jacuzzi`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.hotTub.label'),
            title: t('thematic.popular.hotTub.label'),
          };
        case ThematicLink.Redu:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Redu?TriRecherche=Distance-ASC`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Redu?TriRecherche=Distance-ASC`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Redu?TriRecherche=Distance-ASC`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Redu?TriRecherche=Distance-ASC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.redu.label'),
            title: t('thematic.popular.redu.label'),
          };
        case ThematicLink.Best:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/maisons-de-vacances-les-mieux-notees`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/de-best-beoordeelde-vakantiehuizen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/die-hauser-mit-den-besten-bewertungen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/the-best-rated-holiday-homes`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.best.label'),
            title: t('thematic.popular.best.label'),
          };
        case ThematicLink.Chalets:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Chalets`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Chalets`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Chalets`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Chalets`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.chalet.label'),
            title: t('thematic.popular.chalet.label'),
          };
        case ThematicLink.Dog:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/vacances-avec-chien`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/vakantie-met-hond`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/urlaub-mit-hund`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/pet-friendly-accommodation`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.dog.label'),
            title: t('thematic.popular.dog.label'),
          };
        case ThematicLink.PoolTable:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-99p/Week-end/0000-00-00/billard/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-99p/Weekend/0000-00-00/biljart/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/1-99p/Wochenende/0000-00-00/billardtisch/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/1-99p/Weekend/0000-00-00/billiard/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.poolTable.label'),
            title: t('thematic.popular.poolTable.label'),
          };
        case ThematicLink.Swimmingpool:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/gites-avec-piscine`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/vakantiehuizen-met-zwembad`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/ferienhauser-mit-schwimmbad`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/holiday-houses-with-swimmingpool`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellness.swimmingpool.label'),
            title: t('thematic.wellness.swimmingpool.label'),
          };
        case ThematicLink.Sauna:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/Sejour-avec-Sauna`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/Verblijven-met-Sauna`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/Aufenthalt-mit-Sauna`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/Stays-with-Sauna`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellness.sauna.label'),
            title: t('thematic.wellness.sauna.label'),
          };

        case ThematicLink.SaunaView:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-53p/Week-end/0000-00-00/364_maisons-de-vacances-en-Ardenne/Sauna/terrasse-avec-vue/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-53p/Weekend/0000-00-00/364_vakantiehuizen-in-de-Ardennen/Sauna/terras-met-uitzicht/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/1-53p/Weekend/0000-00-00/364_holiday-Houses-in-the-Ardennes/Sauna/terrace-with-view/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/1-53p/Wochenende/0000-00-00/364_ferienwohnungen-in-den-Ardennen/Sauna/terrasse-mit-Blick/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.sauna.label'),
            title: t('thematic.popular.sauna.label'),
          };
        case ThematicLink.Hotel:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/confort-hotel`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/zoals-op-hotel`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/hotel-comfort`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/wie-im-hotel`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.hotel.label'),
            title: t('thematic.popular.hotel.label'),
          };

        case ThematicLink.HiddenGem:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/perles-rares`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/pareltjes`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/hidden-gems`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/verborgene-schatze`;
                default:
                  return '';
              }
            },
            alt: t('thematic.hiddenGemThematic.title'),
            title: t('thematic.hiddenGemThematic.title'),
          };

        case ThematicLink.EarlyBooking:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-99p/Week-end/2025-01-00/496_maisons-de-vacances-en-Ardenne/early-booking`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-99p/Weekend/2025-01-00/496_vakantiehuizen-in-de-Ardennen/vroegboekdeal`;
                case 'en':
                  return `${prodDomain}/search/1-99p/Weekend/0000-00-00/Promotion/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/1-99p/Wochenende/0000-00-00/Promotion/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.earlyBookingThematic.title'),
            title: t('thematic.earlyBookingThematic.title'),
          };

        case ThematicLink.EarlyBookingBitly:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `https://bit.ly/early-booking-2025-2026`;
                case 'nl':
                  return `https://bit.ly/vroegboekdeal-2025-2026`;
                default:
                  return '';
              }
            },
            alt: t('thematic.earlyBookingThematic.title'),
            title: t('thematic.earlyBookingThematic.title'),
          };

        case ThematicLink.Wellness:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-99p/Week-end/0000-00-00/Jacuzzi/Sauna/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-99p/Weekend/0000-00-00/Jacuzzi/Sauna/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/1-99p/Weekend/0000-00-00/Jacuzzi/Sauna/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/1-99p/Wochenende/0000-00-00/Jacuzzi/Sauna/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellnessThematic.title'),
            title: t('thematic.wellnessThematic.title'),
          };

        case ThematicLink.Winter:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/hiver`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/winter`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/winter`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/winter`;
                default:
                  return '';
              }
            },
            alt: t('thematic.winterThematic.title'),
            title: t('thematic.winterThematic.title'),
          };

        case ThematicLink.Nature:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/se-ressourcer-en-ardenne`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/onthaasten-in-de-ardennen`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/relaxing-in-the-ardennes`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/entschleunigen-in-den-ardennen`;
                default:
                  return '';
              }
            },
            alt: t('thematic.natureThematic.title'),
            title: t('thematic.natureThematic.title'),
          };

        case ThematicLink.Fireplace:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-99p/Week-end/0000-00-00/Feu-ouvert/Chalets/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-99p/Weekend/0000-00-00/Open-haard/Chalets/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/1-99p/Weekend/0000-00-00/Fireplace/Chalets/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/1-99p/Wochenende/0000-00-00/Kamin/Chalets/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.fireplace.label'),
            title: t('thematic.popular.fireplace.label'),
          };

        case ThematicLink.Stays2026:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/recherche/1-99p/Week-end/2026-01-00/?TriRecherche=Satisfaction-DESC`;
                case 'nl':
                  return `${prodDomain}/zoeken/1-99p/Weekend/2026-01-00/?TriRecherche=Satisfaction-DESC`;
                case 'en':
                  return `${prodDomain}/search/1-99p/Weekend/2026-01-00/?TriRecherche=Satisfaction-DESC`;
                case 'de':
                  return `${prodDomain}/suchen/1-99p/Wochenende/2026-01-00/?TriRecherche=Satisfaction-DESC`;
                default:
                  return '';
              }
            },
            alt: t('thematic.popular.stays2026.label'),
            title: t('thematic.popular.stays2026.label'),
          };

        case ThematicLink.UnusualAccommodations:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/week-end-insolite`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/origneel-overnachten`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/originell-ubernachten`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/original-accommodation`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellness.unusualAccommodations.label'),
            title: t('thematic.wellness.unusualAccommodations.label'),
          };
        case ThematicLink.Castles:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/chateaux`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/kastelen`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/schlosser`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/castles`;
                default:
                  return '';
              }
            },
            alt: t('thematic.wellness.castles.label'),
            title: t('thematic.wellness.castles.label'),
          };
        case ThematicLink.ByTheWater:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/bord-eau`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/huizen-aan-het-water`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/am-wasser`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/by-the-water`;
                default:
                  return '';
              }
            },
            alt: t('thematic.campagne.title'),
            title: t('thematic.campagne.title'),
          };

        case ThematicLink.Romantic:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/maisons-de-vacances-ardennes/week-end-en-amoureux`;
                case 'nl':
                  return `${prodDomain}/vakantiehuizen-ardennen/romantisch-weekendje-weg`;
                case 'de':
                  return `${prodDomain}/ferienhauser-ardennen/romantikwochenende`;
                case 'en':
                  return `${prodDomain}/holiday-houses-ardennes/romantic-weekend-breaks`;
                default:
                  return '';
              }
            },
            alt: t('footer.offer_a_gift'),
            title: t('footer.offer_a_gift'),
          };

        case ThematicLink.VoucherOrder:
          return {
            href: () => {
              switch (lng) {
                case 'fr':
                  return `${prodDomain}/cheque-cadeau`;
                case 'nl':
                  return `${prodDomain}/cadeaubon-bestellen`;
                case 'de':
                  return `${prodDomain}/geschenkgutschein-bestellen`;
                case 'en':
                default:
                  return `${prodDomain}/gift-vouchers`;
              }
            },
            alt: t('home.voucherOrder.title'),
            title: t('home.voucherOrder.cta'),
          };

        default:
          throw Error('link not found');
      }
    },
    [
      apiDomain,
      authenticated,
      bookings,
      dayjs,
      getBeProdDomain,
      lng,
      prodDomain,
      t,
    ]
  );

  return useLink<ThematicLink>(getLink);
};
