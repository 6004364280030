import { useGetAELink, AELink } from '@ae/shared';
import { useViewport } from '@ae/shared-ui';
import { Box, Stack, Link, Text } from '@chakra-ui/react';

const InfoSection = () => {
  const { getLinkProps, getLinkTitle } = useGetAELink();
  const { isMobile, isDesktop } = useViewport();

  return (
    <Box data-testid="essential-texts-and-links">
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        spacing="20px"
        alignItems="stretch"
        divider={
          isDesktop ? (
            <Text mx="15px" color="white">
              -
            </Text>
          ) : undefined
        }
      >
        {[
          AELink.Conditions,
          AELink.Insurance,
          AELink.ConfidentialPolicy,
          AELink.Dsa,
        ].map((link) => (
          <Link color="white" {...getLinkProps(link)}>
            {getLinkTitle(link)}
          </Link>
        ))}
      </Stack>

      <Text mt={isMobile ? '2.7rem' : '8px'} color="white">
        <Text as="span" color="rgba(255,255,255,.7)">
          {`©2024 Ardennes-Etape (Asteria SRL) — `}
        </Text>
        Avenue Constant Grandprez 29, B-4970 Stavelot - TVA BE 0473952 094
      </Text>
    </Box>
  );
};

export default InfoSection;
