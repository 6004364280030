import isLocalStorageSupported from './isLocalStorageSupported';

export const getFromLocalStorage = (objectKey: string) => {
  if (isLocalStorageSupported()) {
    const localStorageData = localStorage.getItem(objectKey);
    if (localStorageData) {
      try {
        const result = JSON.parse(localStorageData);
        return result;
      } catch (error) {
        console.warn('impossible to parse localStorageData', localStorageData);
      }
    }
  }
  return undefined;
};
