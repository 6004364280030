import { Locale } from '@ae/i18n';
import { StayDateAvailableJsonldReadStayDateAvailableStayType } from '@ae/data-access';

const StayType = StayDateAvailableJsonldReadStayDateAvailableStayType;

export const stayTypeLabelsByLanguage: {
  [x in Locale]: {
    [z in string]: string;
  };
} = {
  [Locale.Fr]: {
    [StayType.wk]: 'Week-end',
    [StayType.lw]: 'Long-Week-end',
    [StayType.mw]: 'Midweek',
    [StayType['1w']]: 'Semaine',
    [StayType['2w']]: '2-semaines',
  },
  [Locale.Nl]: {
    [StayType.wk]: 'Weekend',
    [StayType.lw]: 'Lang-Weekend',
    [StayType.mw]: 'Midweek',
    [StayType['1w']]: 'Week',
    [StayType['2w']]: '2-weken',
  },
  [Locale.En]: {
    [StayType.wk]: 'Weekend',
    [StayType.lw]: 'Long-Weekend',
    [StayType.mw]: 'MidWeek',
    [StayType['1w']]: 'Week',
    [StayType['2w']]: '2-Weeks',
  },
  [Locale.De]: {
    [StayType.wk]: 'Wochenende',
    [StayType.lw]: 'Langes-Wochenende',
    [StayType.mw]: 'MidWeek',
    [StayType['1w']]: 'Woche',
    [StayType['2w']]: '2-Wochen',
  },
};

export const stayTypesByLabel: {
  [x in Locale]: {
    [z in string]: StayDateAvailableJsonldReadStayDateAvailableStayType;
  };
} = {
  [Locale.Fr]: {
    'Week-end': StayType.wk,
    'Long-Week-end': StayType.lw,
    Midweek: StayType.mw,
    Semaine: StayType['1w'],
    '2-semaines': StayType['2w'],
  },
  [Locale.Nl]: {
    Weekend: StayType.wk,
    'Lang-Weekend': StayType.lw,
    Midweek: StayType.mw,
    Week: StayType['1w'],
    '2-weken': StayType['2w'],
  },
  [Locale.En]: {
    Weekend: StayType.wk,
    'Long-Weekend': StayType.lw,
    MidWeek: StayType.mw,
    Week: StayType['1w'],
    '2-Weeks': StayType['2w'],
  },
  [Locale.De]: {
    Wochenende: StayType.wk,
    'Langes-Wochenende': StayType.lw,
    MidWeek: StayType.mw,
    Woche: StayType['1w'],
    '2-Wochen': StayType['2w'],
  },
};

export type Composition = {
  persons: number;
  dogs: number;
};

export type StayDate = {
  id?: string | null;
  startDate: Date | null;
  endDate: Date | null;
  stayType: StayDateAvailableJsonldReadStayDateAvailableStayType | null;
};

export type SearchValues = {
  composition: Composition | null;
  stayDate: StayDate | null;
  localityId: number | null;
};

export const defaultSearchValues: SearchValues = {
  composition: null,
  stayDate: null,
  localityId: null,
};

export const defaultPersonsCount = 0;
export const defaultDogsCount = 0;
