import { useCallback } from 'react';
import { Locale, useLanguage } from '@ae/i18n';
import { LinkGetter, useGetDomain, useLink, useTranslation } from '@ae/shared';

export enum OwnerLink {
  News,
  Planning,
  Todo,
  RentalPricings,
  Promos,
  Contracts,
  Addresses,
  Statistics,
  Documents,
  HelpOwner,
}

export const useGetOwnerLink = () => {
  const lng = useLanguage();
  const { t } = useTranslation('owner');
  const { getBeProdDomain } = useGetDomain();

  const getLink = useCallback(
    (link: OwnerLink): LinkGetter => {
      const legacyOwnerBasePath = `${getBeProdDomain(
        Locale.Nl
      )}/AdminProprio/${lng}`;

      switch (link) {
        case OwnerLink.News:
          return {
            href: `${legacyOwnerBasePath}/displayActualite.do?ongletOn=actualite`,
            title: t('owner.nav.news'),
            alt: t('owner.nav.news'),
          };
        case OwnerLink.Planning:
          return {
            href: `/planning`,
            title: t('owner.nav.planning'),
            alt: t('owner.nav.planning'),
          };
        case OwnerLink.Todo:
          return {
            href: `${legacyOwnerBasePath}/listAtraiter.do?ongletOn=atraiter`,
            title: t('owner.nav.todo'),
            alt: t('owner.nav.todo'),
          };
        case OwnerLink.RentalPricings:
          return {
            href: `${legacyOwnerBasePath}/displayPricing.do?ongletOn=pricing`,
            title: t('owner.nav.rentalPricings'),
            alt: t('owner.nav.rentalPricings'),
          };
        case OwnerLink.Promos:
          return {
            href: `${legacyOwnerBasePath}/listPromos.do?ongletOn=promos`,
            title: t('owner.nav.promos'),
            alt: t('owner.nav.promos'),
          };
        case OwnerLink.Contracts:
          return {
            href: `${legacyOwnerBasePath}/displayContrats.do?ongletOn=contrats`,
            title: t('owner.nav.contracts'),
            alt: t('owner.nav.contracts'),
          };
        case OwnerLink.Addresses:
          return {
            href: `${legacyOwnerBasePath}/displayCoordonnees.do?ongletOn=coordonnees`,
            title: t('owner.nav.addresses'),
            alt: t('owner.nav.addresses'),
          };
        case OwnerLink.Statistics:
          return {
            href: `${legacyOwnerBasePath}/displayStat.do?ongletOn=statistiques`,
            title: t('owner.nav.statistics'),
            alt: t('owner.nav.statistics'),
          };
        case OwnerLink.Documents:
          return {
            href: `${legacyOwnerBasePath}/displayDocuments.do?ongletOn=documents`,
            title: t('owner.nav.documents'),
            alt: t('owner.nav.documents'),
          };
        case OwnerLink.HelpOwner:
          return {
            href: `${legacyOwnerBasePath}/displaypdf.do`,
            title: t('owner.nav.help'),
            alt: t('owner.nav.help'),
          };
        default:
          throw Error('link not found');
      }
    },
    [lng, t, getBeProdDomain]
  );

  return useLink<OwnerLink>(getLink);
};
