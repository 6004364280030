import { Icon } from '@chakra-ui/react';

type Props = {
  color?: string;
  size?: string;
  space?: string;
  mt?: string;
};

export const DotLoadingIcon = ({ size, space, mt }: Props) => (
  <Icon w={size} h={size} mr={space} mt={mt} viewBox="0 0 100 100" fill="none">
    <circle cx="84" cy="50" r="10" fill="#eeeeee">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1.1904761904761905s"
        calcMode="spline"
        keyTimes="0;1"
        values="10;0"
        keySplines="0 0.5 0.5 1"
        begin="0s"
      ></animate>
      <animate
        attributeName="fill"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="discrete"
        keyTimes="0;0.25;0.5;0.75;1"
        values="#eeeeee;#eeeeee;#eeeeee;#eeeeee;#eeeeee"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#eeeeee">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="0;0;10;10;10"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="16;16;16;50;84"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="50" cy="50" r="10" fill="#eeeeee">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="0;0;10;10;10"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-1.1904761904761905s"
      ></animate>
      <animate
        attributeName="cx"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="16;16;16;50;84"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-1.1904761904761905s"
      ></animate>
    </circle>
    <circle cx="84" cy="50" r="10" fill="#eeeeee">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="0;0;10;10;10"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-2.380952380952381s"
      ></animate>
      <animate
        attributeName="cx"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="16;16;16;50;84"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-2.380952380952381s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#eeeeee">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="0;0;10;10;10"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-3.571428571428571s"
      ></animate>
      <animate
        attributeName="cx"
        repeatCount="indefinite"
        dur="4.761904761904762s"
        calcMode="spline"
        keyTimes="0;0.25;0.5;0.75;1"
        values="16;16;16;50;84"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        begin="-3.571428571428571s"
      ></animate>
    </circle>
  </Icon>
);
