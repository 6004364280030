import { createContext } from 'react';
import { defaultLocale, Locale } from '../helpers/types';

export interface LanguageContextState {
  locale: string;
  supportedLngs: string[];
  changeLocale?: (newLocale: string) => void;
}

export const LanguageContext = createContext<LanguageContextState>({
  locale: defaultLocale as string,
  supportedLngs: [Locale.Nl, Locale.Fr, Locale.En, Locale.De] as string[],
});

export const LanguageProvider = ({
  value,
  children,
}: {
  value: LanguageContextState;
  children: React.ReactElement;
}) => {
  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
