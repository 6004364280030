/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Ardennes étape -- API
 * OpenAPI spec version: 1.0.0
 */

export enum RentalComfort {
  NUMBER_20 = 20,
  NUMBER_25 = 25,
  NUMBER_30 = 30,
  NUMBER_35 = 35,
  NUMBER_40 = 40,
  NUMBER_45 = 45,
  NUMBER_50 = 50,
}
