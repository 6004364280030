import { AELink, useGetAELink, useTranslation } from '@ae/shared';
import Title from './Title';
import Menu from './Menu';

const SupportSection = () => {
  const { t } = useTranslation('footer');
  const { getLinkProps, getLinkTitle } = useGetAELink();

  return (
    <>
      <Title>{t('footer.support')}</Title>
      <Menu>
        {[AELink.FAQ, AELink.HelperCenterContact].map((link) => (
          <Menu.MenuItem {...getLinkProps(link)}>
            {getLinkTitle(link)}
          </Menu.MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SupportSection;
