import { StayDateAvailableJsonldReadStayDateAvailableStayType } from '@ae/data-access';

export const stayTypeLabels = {
  [StayDateAvailableJsonldReadStayDateAvailableStayType.wk]:
    'mr1.date.weekend_label',
  [StayDateAvailableJsonldReadStayDateAvailableStayType.lw]:
    'mr1.date.long_weekend_label',
  [StayDateAvailableJsonldReadStayDateAvailableStayType.mw]:
    'mr1.date.midweek_label',
  [StayDateAvailableJsonldReadStayDateAvailableStayType['1w']]:
    'mr1.date.week_label',
  [StayDateAvailableJsonldReadStayDateAvailableStayType['2w']]:
    'mr1.date.two_weeks',
  [StayDateAvailableJsonldReadStayDateAvailableStayType.custom]:
    'mr1.date.custom',
};
