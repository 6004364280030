import { useEffect } from 'react';
import {
  Container,
  Box,
  useDisclosure,
  Flex,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Spacer,
  DrawerCloseButton,
  DrawerBody,
  HStack,
  Menu,
  MenuList,
  MenuItem as BaseMenuItem,
  Center,
} from '@chakra-ui/react';
import {
  UIBackLink,
  UIMenuItem,
  useMenu,
  UISeparator,
  SeparatorProps,
  useViewport,
  Link,
  LanguageSelector,
  LinkImage,
  PageContainer,
} from '@ae/shared-ui';
import {
  AELink,
  useGetAELink,
  useGetDomain,
  useMe,
  useNotificationBullets,
  useAssets,
  useTranslation,
  AEXLink,
  useGetAEXLink,
} from '@ae/shared';
import { TagElement, useTag } from '@ae/tagging';
import { useLogin } from '@ae/auth';
import { LogoutLink, Mr1, useChangeLocale } from '@ae/shared-comp';
import HamburgerButton from '../components/HamburgerButton';
import SearchRentalReferenceModal from '../components/SearchRentalReferenceModal';
import HeaderTopBar from '../components/HeaderTopBar';
import { Locale, useLanguage } from '@ae/i18n';

const useMr1Tabs = () => {
  const { t } = useTranslation('mr');
  const { getLinkHref } = useGetAELink();
  const { getLinkHref: getAELinkHref } = useGetAEXLink();

  return [
    {
      title: t('mr1.menu.accommodations'),
      url: getLinkHref(AELink.Home),
      ga4: {
        event: 'aex_cta',
        category: 'accomodations',
        action: 'main_cta',
      },
    },
    {
      title: t('mr1.menu.activities'),
      url: getAELinkHref(AEXLink.Activities),
      ga4: {
        event: 'aex_cta',
        category: 'experiences',
        action: 'main_cta',
      },
    },
    {
      title: t('mr1.menu.hikes'),
      url: getAELinkHref(AEXLink.Hikes),
      ga4: {
        event: 'aex_cta',
        category: 'hikes',
        action: 'main_cta',
      },
    },
  ];
};

export type UserLoginChangeParams = {
  login_status: 'logged_in' | 'guest';
  email_address?: string;
  user_id?: number;
};

export type HeaderProps = {
  title?: string;
  showNavigation?: boolean;
  showTitle?: boolean;
  showMr1?: boolean;
  showTopBar?: boolean;
  onUserLoginStatusChange?: (loginData: UserLoginChangeParams) => void;
  hideBackground?: boolean;
};

export const Header = ({
  title,
  showNavigation = false,
  showTitle = false,
  showMr1 = false,
  showTopBar: showTopBarProps = false,
  onUserLoginStatusChange,
  hideBackground = false,
}: HeaderProps) => {
  const { MobileAndTablet, Desktop, isMobile, isMobileOrTablet } =
    useViewport();
  const { data: userInfo, isLoading: isCheckingUser } = useMe();
  const {
    isOpen: isSearchRentalReferenceModalOpen,
    onOpen: onSearchRentalReferenceModalOpen,
    onClose: onSearchRentalReferenceModalClose,
  } = useDisclosure();
  const mr1Tabs = useMr1Tabs();
  const lng = useLanguage();
  const { changeLocale } = useChangeLocale();

  useEffect(() => {
    if (!isCheckingUser && onUserLoginStatusChange) {
      if (userInfo?.id && userInfo?.email) {
        onUserLoginStatusChange({
          user_id: userInfo.id,
          email_address: userInfo.email,
          login_status: 'logged_in',
        });
        return;
      }
      onUserLoginStatusChange({ login_status: 'guest' });
    }
  }, [onUserLoginStatusChange, userInfo, isCheckingUser]);

  const showTopBar =
    showTopBarProps && (lng === Locale.Nl || lng === Locale.Fr);

  return (
    <>
      {showTopBar && <HeaderTopBar />}

      <Box
        bgColor={hideBackground ? 'rgba(0,0,0,0)' : 'ae.green'}
        position="relative"
        data-testid="header"
        id="ae-header"
        zIndex={1000}
      >
        <SearchRentalReferenceModal
          isOpen={isSearchRentalReferenceModalOpen}
          onClose={onSearchRentalReferenceModalClose}
        />
        <MobileAndTablet>
          <MobileNav
            title={title}
            showNavigation={showNavigation}
            showTitle={showTitle}
            onSearchRentalReferenceModalOpen={onSearchRentalReferenceModalOpen}
            onChangeLocale={changeLocale}
          />
        </MobileAndTablet>
        <Desktop>
          <DesktopNav
            title={title}
            showNavigation={showNavigation}
            showTitle={showTitle}
            onSearchRentalReferenceModalOpen={onSearchRentalReferenceModalOpen}
            onChangeLocale={changeLocale}
          />
        </Desktop>
      </Box>

      {showMr1 && (
        <>
          <Center w="100%" background="ae.green">
            <HStack
              mt={isMobileOrTablet ? '14px' : '-4px'}
              gap={isMobile ? '18px' : '26px'}
              pb="18px"
              overflowX="auto"
            >
              {mr1Tabs.map((tab, i) => (
                <TagElement key={tab.title} ga4={{ ...tab.ga4 }}>
                  <Link
                    color={i === 0 ? 'ae.pink' : 'white'}
                    opacity={i === 0 ? 1 : 0.6}
                    fontSize={isMobile ? '18px' : '20px'}
                    href={tab.url}
                    textUnderlineOffset="12px"
                    textDecoration={i === 0 ? 'underline' : 'none'}
                  >
                    {tab.title}
                  </Link>
                </TagElement>
              ))}
            </HStack>
          </Center>

          <Box
            position="relative"
            zIndex={900}
            h={isMobile ? '20px' : '42.5px'}
            background="ae.green"
          >
            <Container
              maxW="container.xl"
              position="absolute"
              left={0}
              right={0}
              px={{ base: '8px', md: '36px' }}
            >
              <Mr1 />
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

type LogoProps = {
  variant: 'mobile' | 'desktop';
  href: string;
};

const Logo = ({ variant }: LogoProps) => {
  const { t } = useTranslation('header');
  const { assetsPath } = useAssets();
  const { prodDomain } = useGetDomain();

  return (
    <TagElement
      ua={{
        eventAction: 'logo',
        eventLabel: 'click',
      }}
      ga4={{
        event: 'menu_cta',
        category: 'logo',
        action: 'click',
      }}
    >
      <LinkImage
        href={prodDomain}
        height={variant === 'desktop' ? '60px' : '45px'}
        src={`${assetsPath}/shared/images/logo.svg`}
        alt={t('header.alt.logo_ae')}
        color="white"
        data-testid="header-logo"
        loading="lazy"
      />
    </TagElement>
  );
};

const MenuSeparator = (props: SeparatorProps) => {
  return <UISeparator my="10px" {...props} />;
};

type LoginLinkProps = {
  withMenu?: boolean;
  asOwner?: boolean;
};

const LoginLink = ({ withMenu = false, asOwner = false }: LoginLinkProps) => {
  const { t } = useTranslation('header');
  const { login } = useLogin();
  const { getLinkHref } = useGetAELink();
  const { data } = useMe();

  const href = asOwner ? getLinkHref(AELink.Owner) : '#';
  const handleLogin = () => login(); // asOwner ? undefined : onRedirect;
  const label = asOwner ? t('header.login.owner') : t('header.login.customer');

  if (data) {
    return null;
  }

  const link = (
    <Link
      display="block"
      width="100%"
      textAlign="left"
      fontSize="15px"
      color="ae.green"
      href={href}
      onClick={handleLogin}
      data-testid="header-login-link"
      px="15px"
      py="13px"
      mt={0}
    >
      {label}
    </Link>
  );

  if (withMenu) {
    return (
      <BaseMenuItem
        w="100%"
        p={0}
        mt={0}
        cursor="default"
        _hover={{ bgColor: 'white' }}
      >
        {link}
      </BaseMenuItem>
    );
  }
  return link;
};

type MobileNavProps = {
  title?: string;
  showNavigation?: boolean;
  showTitle?: boolean;
  onChangeLocale: (locale: string) => void;
  onSearchRentalReferenceModalOpen: () => void;
};

const MobileNav = ({
  title,
  showNavigation,
  showTitle,
  onChangeLocale,
  onSearchRentalReferenceModalOpen,
}: MobileNavProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useMe();
  const { prodDomain } = useGetDomain();
  const { hasProfileNotification, hasBookingNotification } =
    useNotificationBullets();
  const menuItems = useMenu({
    hasBookingNotification,
    hasProfileNotification,
    onSearchRentalReferenceModalOpen,
  });
  const trackEvent = useTag();

  const handleClose = () => {
    trackEvent({
      ua: {
        eventAction: 'close',
        eventLabel: 'click',
      },
      ga4: {
        event: 'menu_cta',
        action: 'click',
        category: 'close',
        website_subsection: 'menu',
      },
    });
    onClose();
  };

  const hasNotification: boolean =
    hasBookingNotification || hasBookingNotification || false;

  const showLogo = !showNavigation && !showTitle;

  return (
    <>
      <Flex flexDir="column" pl="18px" pr="18px">
        <Flex
          height="100%"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
          py="15px"
        >
          <Box display="inline-flex" height="100%" alignItems="center">
            {showLogo && <Logo variant="mobile" href={prodDomain} />}
            {showNavigation && (
              <TagElement
                ua={{
                  eventAction: 'arrow_back',
                  eventLabel: 'click',
                }}
                ga4={{
                  event: 'client_account_cta',
                  category: 'arrow_back',
                  action: 'click',
                }}
              >
                <UIBackLink />
              </TagElement>
            )}
          </Box>

          <HStack spacing="20px">
            <HamburgerButton
              hasNotificationBullet={hasNotification}
              onClick={onOpen}
            />
          </HStack>
        </Flex>

        {showTitle && (
          <Heading
            as="h1"
            fontSize="35px"
            fontFamily="heading"
            color="white"
            pb="15px"
            px="5px"
          >
            {title}
          </Heading>
        )}
      </Flex>

      <Drawer isOpen={isOpen} size="full" onClose={handleClose}>
        <DrawerOverlay />
        {/* Height 100% fix display issue on Iphone */}
        <DrawerContent height="100%">
          <Flex height="80px">
            <Spacer />
            <DrawerCloseButton
              size="20px"
              p="30px"
              color="ae.green"
              outline="none"
              _hover={{ bgColor: 'ae.transparent', outline: 'none' }}
              _active={{ bgColor: 'ae.transparent', outline: 'none' }}
              _selected={{ bgColor: 'ae.transparent', outline: 'none' }}
              _focus={{ bgColor: 'ae.transparent', outline: 'none' }}
              data-testid="drawer-close-button"
            />
          </Flex>
          <DrawerBody
            data-testid="header-drawer"
            display="flex"
            flexDir="column"
            px="30px"
          >
            {menuItems.map((menuItem) => {
              if (menuItem.hide || (menuItem.auth && !data)) {
                return null;
              }
              return (
                <Box key={menuItem.label}>
                  <TagElement
                    ua={{
                      eventLabel: 'main_cta',
                      eventAction: menuItem.gtmLabel,
                    }}
                    ga4={{
                      event: 'menu_cta',
                      action: 'main_cta',
                      website_subsection: 'menu',
                      category: menuItem.gtmLabel,
                    }}
                  >
                    <UIMenuItem
                      href={menuItem.href || '#'}
                      onClick={menuItem.onClick}
                      icon={menuItem.icon}
                      label={menuItem.label}
                      hasNotification={menuItem.bullet || false}
                      my="9px"
                      outline="none"
                      backgroundColor="none"
                    />
                  </TagElement>
                  {menuItem?.seperator && <MenuSeparator />}
                </Box>
              );
            })}
            <MenuSeparator mb="15px" />
            <LoginLink />
            <LoginLink asOwner />
            <LogoutLink />
            <Spacer />
            <Flex mt="30px" mb="30px" flexDir="row" justifyContent="center">
              <LanguageSelector
                onChangeLocale={onChangeLocale}
                ga4={{
                  website_subsection: 'menu',
                }}
              />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

type DesktopNavProps = {
  title?: string;
  showNavigation?: boolean;
  showTitle?: boolean;
  onChangeLocale: (locale: string) => void;
  onSearchRentalReferenceModalOpen: () => void;
};

const DesktopNav = ({
  title,
  showNavigation,
  showTitle,
  onChangeLocale,
  onSearchRentalReferenceModalOpen,
}: DesktopNavProps) => {
  const { t } = useTranslation('header', {
    useSuspense: false,
  });
  const { data } = useMe();
  const { getLinkHref } = useGetAELink();
  const { prodDomain } = useGetDomain();
  const {
    hasProfileNotification,
    hasBookingNotification,
    hasAnyNotifications,
  } = useNotificationBullets();
  const menuItems = useMenu({
    hasBookingNotification,
    hasProfileNotification,
    hideBecomeOwner: true,
    hideHelpCenter: true,
    onSearchRentalReferenceModalOpen,
  });

  return (
    <PageContainer display="block" h="100px">
      <Flex
        height="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Logo variant="desktop" href={prodDomain} />

        <Box display="inline-flex" gap="5px" alignItems="center">
          {showNavigation && (
            <TagElement
              ua={{
                eventAction: 'arrow_back',
                eventLabel: 'click',
              }}
              ga4={{
                event: 'client_account_cta',
                category: 'arrow_back',
                action: 'click',
              }}
            >
              <UIBackLink />
            </TagElement>
          )}
          {showTitle && (
            <Heading
              ml={2}
              as="h1"
              fontSize="30px"
              fontFamily="heading"
              color="white"
            >
              {title}
            </Heading>
          )}
        </Box>

        <Box display={{ base: 'none', md: 'inline-flex' }}></Box>

        <Spacer />

        <HStack spacing="28px">
          <TagElement
            ua={{
              eventLabel: 'main_cta',
              eventAction: 'become_owner',
            }}
            ga4={{
              event: 'menu_cta',
              action: 'main_cta',
              website_subsection: 'menu',
              category: 'become_owner',
            }}
          >
            <Link
              variant="link"
              href={getLinkHref(AELink.BecomeOwner)}
              color="white"
              _visited={{
                color: 'white',
              }}
              fontSize="16px"
            >
              {t('header.ctaLead')}
            </Link>
          </TagElement>

          <TagElement
            ua={{
              eventAction: 'help_center',
            }}
            ga4={{
              website_subsection: 'menu',
              category: 'help_center',
            }}
          >
            <Link
              variant="link"
              href={getLinkHref(AELink.HelpCenter)}
              color="white"
              _visited={{
                color: 'white',
              }}
              fontSize="16px"
            >
              {t('header.help_center')}
            </Link>
          </TagElement>

          {/* <Experiment
            experimentSelector={ExperimentSelector.LanguageHeaderMenu}
            groupSelector={ExperimentGroup.Control}
          >
          <LanguageMenu
            onChangeLocale={onBaseChangeLocale}
            ga4={{
              website_subsection: 'header',
            }}
          />
          </Experiment>  */}

          <Menu placement="bottom-end">
            <HamburgerButton
              isMenuButton
              hasNotificationBullet={hasAnyNotifications}
            />
            <MenuList
              data-testid="header-menu-list"
              position="relative"
              left="20px"
              width="246px"
              borderRadius="20px"
              borderWidth={0}
              overflow="hidden"
              px="12px"
              pt="17px"
              pb="20px"
              boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
              outline="none"
              _hover={{
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
              }}
            >
              {menuItems.map((menuItem) => {
                if (menuItem.hide || (menuItem.auth && !data)) {
                  return null;
                }
                return (
                  <BaseMenuItem
                    key={menuItem.label}
                    cursor="default"
                    w="100%"
                    p={0}
                    // force styles for ae-experience
                    _hover={{
                      bgColor: 'ae.transparent',
                      outline: 'none',
                      textDecoration: 'underline',
                    }}
                    _active={{ bgColor: 'ae.transparent', outline: 'none' }}
                    _selected={{
                      bgColor: 'ae.transparent',
                      outline: 'none',
                    }}
                    _focus={{ bgColor: 'ae.transparent', outline: 'none' }}
                  >
                    <TagElement
                      ua={{
                        eventLabel: 'main_cta',
                        eventAction: menuItem.gtmLabel,
                      }}
                      ga4={{
                        event: 'menu_cta',
                        action: 'main_cta',
                        website_subsection: 'menu',
                        category: menuItem.gtmLabel,
                      }}
                    >
                      <UIMenuItem
                        href={menuItem.href ?? '#'}
                        onClick={menuItem.onClick}
                        icon={menuItem.icon}
                        label={menuItem.label}
                        hasNotification={menuItem.bullet}
                        w="100%"
                        px="15px"
                        py="13px"
                      />
                    </TagElement>
                  </BaseMenuItem>
                );
              })}
              <MenuSeparator />
              <LogoutLink withMenu />
              <LoginLink withMenu />
              <LoginLink withMenu asOwner />

              {/* <Experiment
                experimentSelector={ExperimentSelector.LanguageHeaderMenu}
                groupSelector={ExperimentGroup.Variant1}
              > */}
              <MenuSeparator />
              <Box pt="13px">
                <LanguageSelector
                  menuStyle
                  onChangeLocale={onChangeLocale}
                  ga4={{
                    website_subsection: 'menu',
                  }}
                />
              </Box>
              {/* </Experiment> */}
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </PageContainer>
  );
};
