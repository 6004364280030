import { useGetAELink, AELink, useTranslation } from '@ae/shared';
import { TagElement } from '@ae/tagging';
import Title from './Title';
import Menu from './Menu';

const AESection = () => {
  const { t } = useTranslation('footer');
  const { getLinkProps, getLinkTitle } = useGetAELink();

  return (
    <>
      <Title>{t('footer.title')}</Title>
      <Menu>
        {[AELink.Jobs, AELink.About, AELink.GiftCard].map((link) => (
          <TagElement
            key={link}
            ga4={{
              event: 'client_account_cta',
              action: 'main_cta',
              category: getLinkProps(link)['trackingname'],
            }}
          >
            <Menu.MenuItem {...getLinkProps(link)}>
              {getLinkTitle(link)}
            </Menu.MenuItem>
          </TagElement>
        ))}
      </Menu>
    </>
  );
};

export default AESection;
