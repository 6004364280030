import React from 'react';
import {
  Box,
  forwardRef,
  Select,
  SelectProps as BaseSelectProps,
  Text,
} from '@chakra-ui/react';
import { SelectIcon } from '@ae/icons';
import { UIFormLabel } from '@ae/shared-ui';

type Props = {
  dataTestId?: string;
  placeholder?: string;
  label?: string;
  error?: string | undefined;
  children: React.ReactNode;
  ['aria-label']?: string;
};
export type SelectBoxProps = Props & BaseSelectProps;

export const UISelectBox = forwardRef<SelectBoxProps, 'select'>(
  (
    {
      dataTestId,
      label,
      placeholder,
      children,
      isRequired,
      error,
      mb,
      w,
      ...others
    },
    ref
  ) => (
    <Box mb={mb ?? 4} w={w || 'full'}>
      {label && <UIFormLabel label={label} isRequired={isRequired} />}
      <Select
        ref={ref}
        data-testid={dataTestId}
        bg="white"
        isInvalid={!!error}
        icon={<SelectIcon />}
        iconSize="26px"
        placeholder={placeholder}
        h="40px"
        borderRadius={8}
        color="ae.black"
        borderColor={error ? 'ae.red' : 'ae.grey_300'}
        focusBorderColor={error ? 'ae.red' : 'ae.grey_300'}
        _placeholder={{
          color: 'ae.black',
        }}
        {...others}
      >
        {children}
      </Select>
      {error && <Text color="ae.red">{error}</Text>}
    </Box>
  )
);
