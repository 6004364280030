import { useState, useEffect } from 'react';

export const usePersistedState = <T>(
  name: string,
  defaultValue: T | null = null
) => {
  const [value, setValue] = useState<T | null>(defaultValue);

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem(name);

      storedValue !== null
        ? setValue(JSON.parse(storedValue))
        : localStorage.setItem(name, JSON.stringify(defaultValue));
    } catch {
      setValue(defaultValue);
    }
  }, [defaultValue, name]);

  useEffect(() => {
    try {
      localStorage.setItem(name, JSON.stringify(value));
    } catch {
      // pass
    }
  }, [name, value]);

  return [value, setValue] as const;
};
