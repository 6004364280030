import { useMemo } from 'react';
import { useCrossDomainCookie, useGetDomain } from '@ae/shared';
import { StayDateAvailableJsonldReadStayDateAvailableStayType } from '@ae/data-access';
import {
  defaultSearchValues,
  SearchValues,
  defaultDogsCount,
  defaultPersonsCount,
} from '@ae/shared-comp';

const COOKIE_NAME = 'mr1.searchValues';

type SearchValuesInCookie = {
  persons_count: number | null;
  dogs_count: number | null;
  start_date: string | null;
  end_date: string | null;
  stayType: string | null;
  locality_id: number | null;
};

function convertSearchValuesToCookie(
  searchValues: SearchValues
): SearchValuesInCookie {
  const { composition, stayDate, localityId } = searchValues;
  return {
    persons_count: composition?.persons ?? null,
    dogs_count: composition?.dogs ?? null,
    start_date: stayDate?.startDate?.toISOString() ?? null,
    end_date: stayDate?.endDate?.toISOString() ?? null,
    stayType: stayDate?.stayType ?? null,
    locality_id: localityId ?? null,
  };
}

function convertCookieToSearchValues(
  cookie: SearchValuesInCookie
): SearchValues {
  const searchValues = { ...defaultSearchValues };

  if (cookie.persons_count || cookie.dogs_count) {
    searchValues.composition = {
      persons: cookie?.persons_count ?? defaultPersonsCount,
      dogs: cookie?.dogs_count ?? defaultDogsCount,
    };
  }
  if (cookie.stayType) {
    searchValues.stayDate = {
      startDate: null,
      endDate: null,
      stayType: cookie.stayType
        ? (cookie.stayType as StayDateAvailableJsonldReadStayDateAvailableStayType)
        : null,
    };

    if (cookie.start_date && cookie.end_date) {
      // a stay date in the past is expired.
      if (new Date(cookie.start_date) >= new Date()) {
        searchValues.stayDate = {
          ...searchValues.stayDate,
          startDate: cookie.start_date ? new Date(cookie.start_date) : null,
          endDate: cookie.end_date ? new Date(cookie.end_date) : null,
        };
      }
    }
  }
  if (cookie.locality_id) {
    searchValues.localityId = cookie.locality_id;
  }

  return searchValues;
}

export const useSearchValuesInCookie = () => {
  const { getCookie, setCookie } = useCrossDomainCookie();
  const { prodDomain, getRacineDomain } = useGetDomain();

  const searchValues = useMemo(() => {
    try {
      const cookie = getCookie(COOKIE_NAME);
      if (!cookie) {
        throw new Error('No cookie');
      }
      const searchValues = convertCookieToSearchValues(JSON.parse(cookie));

      // const selectedFolderId =
      //   window?.AEStore?.state?.mr1?.folder?.selectedFolderId;

      // We want to include at least one dog in compositon when dog thematic is active
      if (searchValues.localityId === 371) {
        searchValues.composition = {
          persons: searchValues?.composition?.persons ?? defaultPersonsCount,
          dogs: Math.max(
            searchValues?.composition?.dogs ?? defaultDogsCount,
            1
          ),
        };
      }

      return searchValues;
    } catch (e) {
      return { ...defaultSearchValues };
    }
  }, [getCookie]);

  const setSearchValues = (searchValues: SearchValues) => {
    const searchValuesInCookie = convertSearchValuesToCookie(searchValues);
    const domain = getRacineDomain(prodDomain);

    setCookie(COOKIE_NAME, JSON.stringify(searchValuesInCookie), domain);
  };

  return {
    searchValues,
    setSearchValues,
  };
};
