/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

export const useLongPressTicker = (
  ref: React.RefObject<HTMLButtonElement>,
  onTick: () => void,
  intervalTick?: number
) => {
  const [longPress, setLongPress] = useState(false);
  const timeout = useRef<any>();

  useEffect(() => {
    const timer = setTimeout(() => longPress && onTick(), intervalTick ?? 40);
    return () => clearTimeout(timer);
  }, [intervalTick, longPress, onTick]);

  const start = useCallback(() => {
    timeout.current = setTimeout(() => setLongPress(true), 500);
  }, []);

  const clear = useCallback(() => {
    timeout.current && clearTimeout(timeout.current);
    setLongPress(false);
  }, []);

  useLayoutEffect(() => {
    if (ref && ref.current) {
      const current = ref.current;
      current.addEventListener('mousedown', start);
      current.addEventListener('mouseup', clear);
      current.addEventListener('mouseleave', clear);
      current.addEventListener('touchstart', start);
      current.addEventListener('touchend', clear);
      return () => {
        current.removeEventListener('mousedown', start);
        current.removeEventListener('mouseup', clear);
        current.removeEventListener('mouseleave', clear);
        current.removeEventListener('touchstart', start);
        current.removeEventListener('touchend', clear);
      };
    }
    return () => {};
  }, [clear, ref, start]);
};

export default useLongPressTicker;
