import { useTranslation } from '@ae/shared';
import { UIButton, UIInput } from '@ae/shared-ui';
import { FormControl } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

export type FormValues = {
  listname: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  isSubmitting: boolean;
  defaultValues?: FormValues;
};

export const RentalFavoriteListForm = ({
  onSubmit,
  isSubmitting,
  defaultValues,
}: Props) => {
  const { t } = useTranslation('favorites');
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<FormValues>({ mode: 'onChange', defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl>
        <UIInput
          label={t('favorites.list.creation_subtitle')}
          placeholder={t('favorites.list.creation_placeholder')}
          aria-label={t('favorites.list.creation_subtitle')}
          autoFocus
          maxLength={50}
          {...register('listname', { required: true, maxLength: 50 })}
        />
      </FormControl>

      <UIButton
        mt="10px"
        w="100%"
        borderRadius="50px"
        label={t('favorites.list.creation.validation_cta')}
        type="submit"
        disabled={!isValid || isSubmitting}
        spinning={isSubmitting}
      />
    </form>
  );
};
