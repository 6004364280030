import { StandaloneComponent } from './StandaloneComponent';
import { createRoot } from 'react-dom/client';
import { Footer } from '@ae/shared-comp';
import { standaloneComponentProps } from '../types';

const FooterStandaloneComponent = () => {
  const {
    footer: { hideHelpCenter },
  } = standaloneComponentProps;

  return (
    <StandaloneComponent appName="footer">
      <Footer hideHelpCenter={hideHelpCenter} />
    </StandaloneComponent>
  );
};

const container = document.getElementById('footer');
if (container) {
  const root = createRoot(container);
  root.render(<FooterStandaloneComponent />);
}
