import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Box, List, ListItem, Text } from '@chakra-ui/react';
import Fuse from 'fuse.js';
import { UIButton, UIInput, useViewport } from '@ae/shared-ui';
import { LocalityJsonldReadLocality } from '@ae/data-access';
import { useTranslation } from '@ae/shared';

const fuseOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name', 'sanitizedName'],
};

type Props = {
  localities?: LocalityJsonldReadLocality[];
  onChange: (value: LocalityJsonldReadLocality | null) => void;
  onReset: () => void;
  showOverallInArdeneButton?: boolean;
  bottomComponent?: React.ReactNode;
};

export const LocalityPicker = forwardRef<{ focus: () => void }, Props>(
  (
    {
      localities,
      onChange,
      onReset,
      showOverallInArdeneButton = false,
      bottomComponent,
    },
    ref
  ) => {
    const { isDesktop } = useViewport();
    const { t } = useTranslation('mr');
    const inputRef = useRef<HTMLInputElement>(null);
    const [fuse, setFuse] = useState(new Fuse(localities ?? [], fuseOptions));
    const [results, setResults] = useState<LocalityJsonldReadLocality[]>([
      ...(localities ?? []),
    ]);

    // If localities are loaded after the component is mounted
    useEffect(() => {
      if (localities) {
        setFuse(new Fuse(localities, fuseOptions));
        setResults([...localities]);
      }
    }, [localities, setFuse]);

    useImperativeHandle(
      ref,
      () => {
        return {
          focus() {
            inputRef.current && inputRef.current.focus();
          },
        };
      },
      []
    );

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const q = e.target.value;
      setResults(q ? fuse.search(q).map(({ item }) => item) : localities ?? []);
    };

    const ListTitle = ({ title }: { title: string }) => (
      <Text p="8px" color="ae.green" fontWeight="bold">
        {title}
      </Text>
    );

    const ListLocalityItem = ({
      locality,
    }: {
      locality: LocalityJsonldReadLocality;
    }) => (
      <ListItem>
        <Text
          p="8px"
          ml="10px"
          cursor="pointer"
          _hover={
            isDesktop
              ? { bgColor: 'ae.green', borderRadius: '5px', color: 'white' }
              : {}
          }
          _active={{ bgColor: 'ae.green', borderRadius: '5px', color: 'white' }}
          onClick={() => onChange(locality)}
        >
          {locality.name}
        </Text>
      </ListItem>
    );

    return (
      <Box data-testid="localities-picker" w="100%">
        <UIInput
          ref={inputRef}
          dataTestId="localities-picker-search-input"
          borderRadius="20px"
          onChange={onInputChange}
          placeholder={t('mr1.locality.search_placeholder')}
          _focus={{ borderColor: 'black', borderWidth: '2px' }}
        />
        <Box
          h="300px"
          overflowY="scroll"
          __css={{
            '&::-webkit-scrollbar': {
              w: '1',
            },
            '&::-webkit-scrollbar-track': {
              w: '5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: `ae.grey_200`,
            },
          }}
        >
          <List>
            <ListItem>
              <ListTitle title={t('mr1.locality.best_destinations_label')} />
            </ListItem>
            {results
              ?.filter((r) => r.isTopDestination)
              .map((locality) => (
                <ListLocalityItem key={locality.name} locality={locality} />
              ))}
          </List>

          <List>
            <ListItem>
              <ListTitle title={t('mr1.locality.other_cities_label')} />
            </ListItem>
            {results
              ?.filter((r) => !r.isTopDestination)
              .map((locality) => (
                <ListLocalityItem key={locality.name} locality={locality} />
              ))}
          </List>
        </Box>

        <Box w="100%" h="1px" bgColor="ae.grey_200" mb="20px" />

        {showOverallInArdeneButton && (
          <UIButton
            px={0}
            w="100%"
            variant="tertiary"
            onClick={() => onReset()}
          >
            {t('mr1.locality.selection_cta')}
          </UIButton>
        )}

        {bottomComponent}
      </Box>
    );
  }
);
