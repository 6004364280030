import { MouseEventHandler, ReactNode } from 'react';

export const InsideTextLink = ({
  href,
  onClick,
  target = '_blank',
  children,
}: {
  href?: string;
  target?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  children?: ReactNode;
}) => (
  <a
    href={href || '#'}
    target={href ? target : '_self'}
    onClick={onClick}
    style={{
      textDecoration: 'underline',
      fontWeight: 'bold',
      color: '#003F35',
    }}
    aria-label={children as string}
  >
    {children}
  </a>
);
