import { extendTheme } from '@chakra-ui/react';
import { buttonTheme } from './components/buttonTheme';
import { badgeTheme } from './components/badgeTheme';
import { checkboxTheme } from './components/checkboxTheme';
import { headingTheme } from './components/headingTheme';
import { switchTheme } from './components/switchTheme';
import { radioTheme } from './components/radioTheme';
import { accordionTheme } from './components/accordionTheme';
import { alertTheme } from './components/alertTheme';

import '@fontsource/lato';
import '@fontsource/lato/400.css';
import '@fontsource/lato/400-italic.css';

import '@fontsource/dm-serif-text';
import '@fontsource/dm-serif-text/400.css';
import '@fontsource/dm-serif-text/400-italic.css';

export const sharedTheme = extendTheme({
  fonts: {
    heading: 'DM Serif Text',
    body: 'Lato',
  },
  breakpoints: {
    // base: From 0em upwards
    sm: '30em',
    md: '56em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  sizes: {
    max: 'max-content',
    min: 'min-content',
    full: '100%',
    '3xs': '14rem',
    '2xs': '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem', // 672px
    '3xl': '48rem', // 768px
    '4xl': '56rem', // 896px
    '5xl': '64rem', // 1024px
    '6xl': '72rem', // 1152px
    '7xl': '80rem', // 1280px
    '8xl': '90rem', // 1440px
    container: {
      xl: '1196px',
    },
  },
  colors: {
    ae: {
      white: '#ffffff',
      whiteAlpha20: 'rgba(255,255,255,0.2)',
      whiteAlpha40: 'rgba(255,255,255,0.4)',
      whiteAlpha60: 'rgba(255,255,255,0.6)',
      whiteAlpha80: 'rgba(255,255,255,0.8)',

      orange: '#D96F0E',
      orange_100: '#e9a281',
      yellow: '#FFC000',
      green: '#003f35',
      green_100: '#E5ECEB',
      green_200: '#CCD9D7',
      green_400: '#99B2AE',
      green_600: '#668C86',
      green_800: '#33655D',

      purple: '#6B4391',

      pink: '#fbd7c9',
      pink_200: '#FEF7F4',
      pink_400: '#FDEFE9',
      pink_600: '#FDE7DF',
      pink_800: '#FCDFD4',

      black: '#1d1d1b',
      grey_50: '#F3F3F3',
      grey_100: '#f1f1f1',
      grey_200: '#DCDCDC',
      grey_300: '#c4c4c4',
      grey_400: '#707070',
      greyE5: '#E5E5E5',
      grey97: '#979797',
      red: '#B42025',
      success: '#9CC813',
      success_100: '#80A312',
      warning: '#CB801F',
      transparent: 'rgba(0,0,0,0)',
      modalBg: 'rgba(0,63,53,0.32)',
      imageFilter: 'rgba(0, 63, 53, 0.35)',
    },

    error: {
      100: '#FFEFF1',
      400: '#d9878b',
      900: '#B42025',
    },
    success: {
      100: '#d6dfbb',
      400: '#9cc813',
      900: '#9CC813',
    },
    warning: {
      100: '#f2ebce',
      200: '#CB801F',
      300: '#FFF4D3',
      400: '#f8d567',
      900: '#ffc000',
    },
    checkBox: {
      500: '#fbd7c9', // you need this
    },
  },
  shadows: {
    base: '0px 2px 3px rgba(0, 0, 0, 0.119509), 0px 8px 14px rgba(74, 74, 87, 0.08)',
    blur: '0px 0px 5px rgba(0, 0, 0, 0.119509), 0px 0px 14px rgba(74, 74, 87, 0.08)',
    level_1: '0px 6px 6px 0px #003F351A',
    level_2: '0px 12px 12px 0px #003F351A',
    level_3: '0px 18px 18px 0px #003F351A',
  },
  radii: {
    base: '5px',
    box: '8px',
    button: '20px',
  },
  components: {
    Heading: headingTheme,
    Button: buttonTheme,
    Badge: badgeTheme,
    Checkbox: checkboxTheme,
    Switch: switchTheme,
    Radio: radioTheme,
    Select: {
      baseStyle: {
        field: {
          borderRadius: 8,
          borderColor: 'ae.grey_300',
          paddingRight: '40px',
          lineHeight: '40px',
          _placeholder: {
            color: 'ae.black',
          },
        },
      },
    },
    Accordion: accordionTheme,
    Alert: alertTheme,
    Drawer: {
      parts: ['dialog, dialogContainer, overlay'],

      variants: {
        adminPanel: {
          overlay: {},
          dialogContainer: {},
          dialog: {
            bgColor: 'ae.grey_100',
            borderLeft: '1px solid #DCDCDC',
          },
        },
        adminPanelClickThrough: {
          // clickthrough: https://github.com/chakra-ui/chakra-ui/issues/2893
          // allow to click through the modal overlay to continue interacting with the page
          overlay: {
            pointerEvents: 'none',
            background: 'transparent',
          },
          dialogContainer: {
            pointerEvents: 'none',
            background: 'transparent',
          },
          dialog: {
            pointerEvents: 'auto',
            py: 10,
            px: 5,
            width: '350px',
            bgColor: '#F9F8F8',
          },
        },
      },
    },

    Table: {
      variants: {
        simple: {
          th: {
            borderColor: '#DCDCDC',
            fontFamily: 'Lato',
          },
          td: {
            borderColor: '#DCDCDC',
          },
        },
        admin: {
          table: {
            fontVariantNumeric: 'lining-nums tabular-nums',
            borderCollapse: 'collapse',
            width: 'full',
          },
          th: {
            fontFamily: 'body',
            color: 'ae.green',
            fontWeight: 'bold',
            textTransform: 'none',
            paddingBottom: 3,
            fontSize: 'sm',
            textAlign: 'start',
            borderRight: '1px solid #DCDCDC',
            borderBottom: '1px solid #DCDCDC',
          },
          td: {
            textAlign: 'start',
            paddingY: 1,
            paddingLeft: 4,
            paddingRight: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            border: '1px solid #DCDCDC',
          },
          caption: {
            mt: 4,
            fontFamily: 'heading',
            textAlign: 'center',
            fontWeight: 'medium',
          },
        },
      },
    },
  },
});
