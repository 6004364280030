import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { CalendarIcon } from '@ae/icons';
import { forwardRef, LegacyRef, MouseEventHandler } from 'react';

type Props = ReactDatePickerProps;
registerLocale('fr', fr);

export const UIDatePicker = ({
  dateFormat = 'dd / MM / yyyy',
  ...datePickerProps
}: Props & ReactDatePickerProps) => {
  // DOC : https://reactdatepicker.com/#example-custom-input
  const CustomInput = forwardRef(
    (
      {
        value,
        onClick,
        name,
      }: { value?: string; onClick?: MouseEventHandler; name?: string },
      ref: LegacyRef<HTMLInputElement>
    ) => (
      <InputGroup onClick={onClick}>
        <InputLeftElement pointerEvents="none">
          <CalendarIcon />
        </InputLeftElement>
        <Input ref={ref} value={value} aria-label={name} />
      </InputGroup>
    )
  );

  return (
    <DatePicker
      {...datePickerProps}
      dateFormat={dateFormat}
      locale="fr"
      customInput={<CustomInput />}
    />
  );
};
