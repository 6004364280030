import { Global } from '@emotion/react';
import { useAssets } from '@ae/shared';

export const Fonts = () => {
  const { cssUrl } = useAssets();

  return (
    <Global
      styles={`
        @font-face {
          font-family: 'Lato';
          font-style: normal;
          font-display: swap;
          src: ${cssUrl(
            '/shared/fonts/lato/lato-v17-latin-ext_latin-regular.woff2'
          )} format('woff2'), ${cssUrl(
        '/shared/fonts/lato/lato-v17-latin-ext_latin-regular.woff'
      )} format('woff');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
          font-family: 'DM Serif Text';
          font-style: normal;
          font-display: swap;
          font-weight:bold;
          src: ${cssUrl(
            '/shared/fonts/DM/dm-serif-text-v5-latin-ext_latin-regular.woff2'
          )} format('woff2'), ${cssUrl(
        '/shared/fonts/DM/dm-serif-text-v5-latin-ext_latin-regular.woff'
      )} format('woff');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        
        @font-face {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 400;
          src: ${cssUrl(
            '/shared/fonts/work_sans/work_sans.woff2'
          )} format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

      `}
    />
  );
};
