/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetHrefFunc = (...args: any) => string;

export type LinkGetter = {
  href: GetHrefFunc | string;
  title?: string | undefined;
  alt?: string | undefined;
  target?: string | undefined;
  icon?: React.ReactElement | undefined;
  image?: string | undefined;
  datatestid?: string | undefined;
  trackingname?: string | undefined;
};

type LinkProps = {
  href: string;
  title: string | undefined;
  alt: string | undefined;
  target?: string | undefined;
  icon?: React.ReactElement | undefined;
  image?: string | undefined;
  'aria-label': string;
  'data-testid'?: string | undefined;
  key?: string | undefined;
  trackingname?: string | undefined;
};

export const useLink = <T,>(getLink: (link: T) => LinkGetter) => {
  const getLinkProps = useCallback(
    (link: T, ...args: any): LinkProps => {
      const { href, target, title, alt, datatestid, trackingname } =
        getLink(link);

      return {
        href: typeof href === 'string' ? href : href(...args),
        key: alt ?? title,
        title,
        alt,
        target,
        'aria-label': title ?? '',
        'data-testid': datatestid,
        trackingname: trackingname ? trackingname : title,
      };
    },
    [getLink]
  );

  const getLinkTitle = useCallback(
    (link: T) => getLink(link)?.title,
    [getLink]
  );

  const getLinkHref = useCallback(
    (link: T, ...args: any): string => {
      const href = getLink(link)?.href;
      return typeof href === 'string' ? href : href(...args);
    },
    [getLink]
  );

  const getLinkIcon = useCallback((link: T) => getLink(link)?.icon, [getLink]);

  const getLinkImage = useCallback(
    (link: T) => getLink(link)?.image,
    [getLink]
  );

  const getLinkAlt = useCallback((link: T) => getLink(link)?.alt, [getLink]);

  return {
    getLinkProps,
    getLinkTitle,
    getLinkHref,
    getLinkIcon,
    getLinkImage,
    getLinkAlt,
  };
};
