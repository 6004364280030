import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useLanguage } from '@ae/i18n';
import { ChevronDownIcon } from '@ae/icons';

import {
  UICountryFlag,
  UIButton,
  UIRoundIcon,
  LanguageSelector,
} from '@ae/shared-ui';
import { useMemo } from 'react';
import { useTranslation } from '@ae/shared';

export enum LanguageMenuSelectorStyle {
  Icon,
  Button,
}

type Props = {
  selectorStyle?: LanguageMenuSelectorStyle;
  onChangeLocale: (locale: string) => void;
  ga4?: { website_subsection: string };
};

const fixedLangueMenuWidth = '230px';

export const LanguageMenu = ({
  selectorStyle = LanguageMenuSelectorStyle.Icon,
  onChangeLocale,
  ga4,
}: Props) => {
  const { t } = useTranslation('common');
  const language = useLanguage();

  const languageLabel = useMemo(() => {
    switch (language) {
      case 'fr':
        return t('common.language.fr');
      case 'nl':
        return t('common.language.nl');
      case 'en':
        return t('common.language.en');
      case 'de':
        return t('common.language.de');
      default:
        return '';
    }
  }, [language, t]);

  return (
    <Menu placement="bottom-end">
      {selectorStyle === LanguageMenuSelectorStyle.Icon && (
        <Tooltip
          label={t('common.language_menu')}
          bgColor="ae.grey_100"
          color="ae.green"
          padding="5px 10px"
          fontWeight="bold"
          hasArrow={true}
          arrowSize={6}
        >
          <MenuButton
            as={UIRoundIcon}
            variant="iconOutlined"
            h="40px"
            w="40px"
            data-testid="language-menu-button"
            aria-label={t('common.language_menu')}
            icon={
              <Flex justifyContent="center" alignItems="center">
                <UICountryFlag />
              </Flex>
            }
          ></MenuButton>
        </Tooltip>
      )}
      {selectorStyle === LanguageMenuSelectorStyle.Button && (
        <MenuButton
          as={UIButton}
          variant="iconOutlined"
          h="63px"
          minW={fixedLangueMenuWidth} // This is a hack, seems to be a problem with the size of content and children with menuButton
          maxW={fixedLangueMenuWidth} // This is a hack, seems to be a problem with the size of content and children with menuButton
          data-testid="language-menu-button"
        >
          <Flex
            w={fixedLangueMenuWidth}
            px="15px"
            flexDir="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <UICountryFlag />
            <Text
              wordBreak="normal"
              pl="10px"
              color="white"
              align="left"
              fontWeight="normal"
            >
              {languageLabel}
            </Text>
            <Spacer />
            <Box
              minH="20px"
              minW="20px"
              borderRadius="20px"
              bgColor="rgba(255,255,255,0.1)"
              display="flex"
              flexDir="row"
              justifyContent="center"
              alignItems="center"
              color="white"
            >
              <ChevronDownIcon color="white" size="10px" />
            </Box>
          </Flex>
        </MenuButton>
      )}
      <MenuList>
        <LanguageSelector onChangeLocale={onChangeLocale} ga4={ga4} />
      </MenuList>
    </Menu>
  );
};
