import { useLanguage } from '@ae/i18n';
import { useMe } from '@ae/shared';
import { TagProvider as TagBaseProvider } from '@ae/tagging';

export const TagProvider = ({ children }: { children: React.ReactElement }) => {
  const { data: user } = useMe();
  const language = useLanguage();

  return (
    <TagBaseProvider
      userId={user?.id}
      userEmail={user?.email}
      language={language}
    >
      {children}
    </TagBaseProvider>
  );
};
