import { useCallback } from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PinterestIcon,
  YoutubeIcon,
} from '@ae/icons';
import { LinkGetter, useLink } from '../hooks';
import { useTranslation } from '../providers';

export enum SocialLink {
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Youtube,
}

export const useSocialLinks = () => {
  const { t } = useTranslation('footer');

  const getLink = useCallback(
    (link: SocialLink): LinkGetter => {
      switch (link) {
        case SocialLink.Facebook:
          return {
            href: 'https://www.facebook.com/ArdennesEtape',
            title: t('footer.alt_facebook'),
            alt: t('footer.alt_facebook'),
            target: '_blank',
            icon: <FacebookIcon />,
          };
        case SocialLink.Instagram:
          return {
            href: 'https://www.instagram.com/ardennes_etape/',
            title: t('footer.alt_instagram'),
            alt: t('footer.alt_instagram'),
            target: '_blank',
            icon: <InstagramIcon />,
          };
        case SocialLink.LinkedIn:
          return {
            href: 'https://www.linkedin.com/company/ardennes-etape',
            title: t('footer.alt_linkedin'),
            alt: t('footer.alt_linkedin'),
            target: '_blank',
            icon: <LinkedInIcon />,
          };
        case SocialLink.Pinterest:
          return {
            href: 'https://www.pinterest.com/ardennesetape/',
            title: t('footer.alt_pinterest'),
            alt: t('footer.alt_pinterest'),
            target: '_blank',
            icon: <PinterestIcon />,
          };
        case SocialLink.Youtube:
          return {
            href: 'https://www.youtube.com/user/ArdennesEtape',
            title: t('footer.alt_youtube'),
            alt: t('footer.alt_youtube'),
            target: '_blank',
            icon: <YoutubeIcon />,
          };
        default:
          throw Error('link not found');
      }
    },
    [t]
  );

  return useLink<SocialLink>(getLink);
};
