const isLocalStorageSupported = () => {
  try {
    const testKey = '__isLocalStorageSupportedTestKey__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export default isLocalStorageSupported;
