/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Ardennes étape -- API
 * OpenAPI spec version: 1.0.0
 */

export enum CustomCalendarPeriodPeriodType {
  custom = 'custom',
}
