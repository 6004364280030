import { ChangeEvent, useState, useEffect } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { useDate, useTranslation } from '@ae/shared';
import { UIInput } from '@ae/shared-ui';

type Props = {
  label?: string;
  maxYear?: number;
  minYear?: number;
};

export const DateInput = <FV extends object>({
  label,
  maxYear,
  minYear,
  control,
  name,
}: Props & UseControllerProps<FV>) => {
  const { dayjs } = useDate();
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const {
    field: { value, onChange },
  } = useController({ name, control });

  useEffect(() => {
    if (typeof value !== 'string') return;
    const date = dayjs(value);
    setDay(date.date().toString());
    setMonth((date.month() + 1).toString());
    setYear(date.year().toString());
    // Avoid eslint missing dependency warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!day || !month || !year) return;
    if (
      parseInt(day, 10) > 31 ||
      parseInt(day, 10) < 1 ||
      parseInt(month, 10) > 12 ||
      parseInt(month, 10) < 1 ||
      (maxYear && parseInt(year, 10) > maxYear) ||
      (minYear && parseInt(year, 10) < minYear)
    )
      return;
    let date = dayjs();
    date = date.date(parseInt(day, 10));
    date = date.month(parseInt(month, 10) - 1);
    date = date.year(parseInt(year, 10));
    const event = {
      target: { value: date.toISOString(), name },
    };
    onChange(event);
    // Avoid eslint missing dependency warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year]);

  const handleChangeDay = (e: ChangeEvent<HTMLInputElement>) =>
    setDay(e.target.value);
  const handleChangeMonth = (e: ChangeEvent<HTMLInputElement>) =>
    setMonth(e.target.value);
  const handleChangeYear = (e: ChangeEvent<HTMLInputElement>) =>
    setYear(e.target.value);

  const { t } = useTranslation('profile');

  return (
    <Box mb="20px">
      {label && (
        <Text fontWeight="bold" mb="10px" fontSize="14px">
          {label}
        </Text>
      )}
      <SimpleGrid columns={3} spacing={3}>
        <Box>
          <UIInput
            max={31}
            min={1}
            mb="0px"
            name={t('profil.data.birth_date.day')}
            label={t('profil.data.birth_date.day')}
            type="number"
            placeholder={t('profil.data.birth_date.day')}
            value={day}
            onChange={handleChangeDay}
          />
        </Box>
        <Box>
          <UIInput
            max={12}
            min={1}
            mb="0px"
            name={t('profil.data.birth_date.month')}
            label={t('profil.data.birth_date.month')}
            type="number"
            placeholder={t('profil.data.birth_date.month')}
            value={month}
            onChange={handleChangeMonth}
          />
        </Box>
        <Box>
          <UIInput
            max={maxYear}
            min={minYear}
            mb="0px"
            name={t('profil.data.birth_date.year')}
            label={t('profil.data.birth_date.year')}
            type="number"
            placeholder={t('profil.data.birth_date.year')}
            value={year}
            onChange={handleChangeYear}
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

DateInput.defaultProps = {
  maxYear: new Date().getFullYear(),
  minYear: 1900,
};

DateInput.displayName = 'Input';
export default DateInput;
