import { Box } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};

export const UIAlertIconDisplay = ({ children }: Props) => (
  <Box
    bg="ae.white"
    minW="32px"
    minH="32px"
    h="32px"
    w="32px"
    borderRadius="50%"
    textAlign="center"
    lineHeight="30px"
  >
    {children}
  </Box>
);
