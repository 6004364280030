import { useGetDomain, useTranslation } from '@ae/shared';
import { UIButton, ErrorField, UIInput, UIModal } from '@ae/shared-ui';
import { TagElement } from '@ae/tagging';
import { FormControl, Grid, GridItem } from '@chakra-ui/react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = {
  reference: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const SearchRentalReferenceModal = ({
  isOpen,
  onClose: onBaseClose,
}: Props) => {
  const { t } = useTranslation('header');
  const { prodDomain } = useGetDomain();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const onClose = () => {
    reset();
    onBaseClose();
  };

  const onSubmit = ({ reference }: FormValues) => {
    window.open(prodDomain + '/' + reference);
    reset();
  };

  const initialRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register('reference', {
    required: true,
    pattern: /^[0-9]{6}-[0-9]{2}$/,
  });

  return (
    <UIModal
      initialFocusRef={initialRef}
      dataTestId="search-rental-reference-modal"
      title={t('header.modal.reference.title')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns={{ md: '1fr auto', sm: '1fr' }} gap="16px">
          <GridItem>
            <FormControl isRequired>
              <UIInput
                mb="0"
                flexGrow={2}
                type="text"
                placeholder="107602-01"
                aria-label={t('header.modal.reference.title')}
                ref={(e) => {
                  ref(e);
                  initialRef.current = e;
                }}
                {...rest}
              />
              {errors.reference && <ErrorField errorField={errors.reference} />}
            </FormControl>
          </GridItem>

          <GridItem>
            <TagElement
              ga4={{
                event: 'menu_cta',
                website_subsection: 'menu',
                category: 'search_reference',
                action: 'main_cta',
              }}
            >
              <UIButton
                type="submit"
                variant="primary"
                aria-label={t('header.modal.reference.cta')}
                label={t('header.modal.reference.cta')}
                h="42px" // TODO use size when new input ui
              />
            </TagElement>
          </GridItem>
        </Grid>
      </form>
    </UIModal>
  );
};

export default SearchRentalReferenceModal;
