import { List, LinkProps, ListItem } from '@chakra-ui/react';
import { Link } from '@ae/shared-ui';

type MenuProps = {
  children: React.ReactNode;
};

const Menu = ({ children }: MenuProps) => (
  <List spacing="20px">{children}</List>
);

type MenuItemProps = {
  href: string;
  children: React.ReactNode;
};
Menu.displayName = 'Menu';

const MenuItem = ({ href, children, ...props }: MenuItemProps & LinkProps) => (
  <ListItem>
    <Link href={href} {...props} variant="link-white">
      {children}
    </Link>
  </ListItem>
);
MenuItem.displayName = 'MenuItem';

Menu.MenuItem = MenuItem;

export default Menu;
